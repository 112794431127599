<template>
  <v-container fill-height>
    <v-layout justify-center fill-height>
    <v-flex xs12 sm8 md6>
    <v-card class="fill-height">
      <v-layout column fill-height>
      <v-card-title class="pt-0">
        <v-layout align-baseline>
          <h3 class="title mr-3">Aufträge</h3>
          <v-spacer></v-spacer>
          <v-flex>
            <v-select
              v-model="selection"
              :items="items"
              :item-text="formatItemTitle"
              :item-disabled="disabledItem"
              single-line
              hide-details
              dense
              menu-props="offsetY, allowOverflow">
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ formatItemTitle(data.item) }}</v-list-tile-title>
                    <v-list-tile-sub-title class="caption" v-if="formatItemSubTitle(data.item)">{{ formatItemSubTitle(data.item) }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action v-if="data.item.icon">
                    <v-icon small color="grey lighten-1">{{ data.item.icon }}</v-icon>
                  </v-list-tile-action>
                </template>                
              </template>
            </v-select>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-data-table
          ref="dTable"
          :items="printJobsSelected"
          item-key="jobId"
          :headers-length="2"
          hide-headers
          :hide-actions="printJobsSelected.length <= 5">
          <template slot="items" slot-scope="props">
            <tr 
              @click.prevent="props.expanded = !props.expanded"
              :key="props.item.jobId">
              <td class="px-2 py-1 text-xs-center" style="width: 24px">
                <v-fade-transition mode="out-in">
                <v-icon key="completed"
                  v-if="props.item.completed"
                  :color="props.item.completed === 1 ? 'success' : 'grey darken-3'">check</v-icon>
                <v-progress-circular key="printing"
                  v-else-if="props.item.printing"
                  :size="24"
                  indeterminate
                  color="info"></v-progress-circular>
                <v-icon key="highlighted"
                  v-else-if="props.item.highlighted"
                  color="grey darken-3">attach_file</v-icon>                  
                <span key="error"
                  v-else-if="props.item.error"><svg
                  xmlns="http://www.w3.org/2000/svg"                
                  :fill="$vuetify.theme.error"
                  width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19.1 17H22v-6c0-1.7-1.3-3-3-3h-9l9.1 9zm-.1-7c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-1-3V3H6v1.1L9 7zM1.2 1.8L0 3l4.9 5C3.3 8.1 2 9.4 2 11v6h4v4h11.9l3 3 1.3-1.3-21-20.9zM8 19v-5h2.9l5 5H8z"/>
                  <path fill="none" d="M0 0h24v24H0V0z"/>
                </svg></span>
                <v-icon v-else color="grey darken-3" key="intermediate">print</v-icon>
                </v-fade-transition>
              </td>
              <td class="px-2 py-1">
                <div class="body-2">
                  <span class="font-weight-bold">{{ props.item.data.etikettValues.title }} </span>
                  <small v-if="props.item.data.produkt && props.item.data.produktChanged">geändert</small>
                </div>
                <div class="grey--text text--darken-1">{{ formatZutaten(props.item.data.etikettValues.zutaten, { details: false }) }}</div>
                <v-layout>
                  <v-flex class="mr-3" shrink>
                    <sub>MHD</sub>
                    <span :class="{'font-weight-bold': !!props.item.data.etikett.mhd }">
                      {{ formatDate(props.item.data.etikettValues.mhd, 'DD.MM.YYYY') }}
                    </span>
                  </v-flex>
                  <v-flex shrink>
                    <sub>FM</sub>
                    <span :class="{'font-weight-bold': !!props.item.data.etikett.fuellMenge }">
                      {{ props.item.data.etikettValues.fuellMenge }} {{ props.item.data.etikettValues.fuellMengeUnit }}
                    </span>
                  </v-flex>
                  <v-flex class="text-xs-right" style="margin-left: auto">
                    <span :class="{'font-weight-bold': !!props.item.data.etikett.count }">
                      {{ props.item.data.etikettValues.count }} x
                    </span>
                    <span :class="{'font-weight-bold': !!props.item.data.etikett.vorlage.sizeId }">
                      {{ props.item.data.etikettValues.vorlage.sizeId }}
                    </span>
                  </v-flex>                  
                </v-layout>
              </td>
            </tr>
          </template>
          <template slot="expand" slot-scope="props">
            <v-card flat color="grey lighten-4" >
              <v-card-actions>
                <v-menu offset-y class="align-self-start mr-1">
                  <v-btn slot="activator" small icon>
                    <v-icon>menu</v-icon>
                  </v-btn>
                  <v-list dense>
                    <v-list-tile @click="deleteJob(props.item.jobId)">
                      <v-list-tile-title>Löschen</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout>
                      <v-btn small flat
                        :to="`/etiketten/${props.item.jobId}`">
                        Zum Etikett</v-btn>
                      <v-btn 
                        v-if="props.item.data.produkt"
                        small flat
                        :to="`/katalog/${props.item.data.produkt.slug}`">
                        Zum Produkt</v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout v-if="props.item.error && !props.item.completed">
                      <v-btn small flat
                        @click="printJob(props.item.jobId); props.expanded = false">
                        Nochmal versuchen</v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>            
          </template>
          <template v-slot:no-data v-if="selection === 'today'">
            <div class="text-xs-center my-3">
            <img width="50%" src="@/assets/sunrise.svg" />
            </div>
          </template>          
        </v-data-table>
      </v-card-text>
      <div style="margin-top: auto">      
      <v-card-title>
        <v-layout align-baseline>
          <h3 class="title">Drucker</h3>
          <v-spacer></v-spacer>
          <v-btn small flat class="ma-0"
            @click.native="refreshPrinterStatus"
            :loading="loadingPrinterStatus">
            Status<v-icon right>refresh</v-icon></v-btn>              
        </v-layout>
      </v-card-title>        
      <v-card-text class="pt-0">
        <v-alert :value="true"
          :type="alertType" outline
          :color="alertType === 'info' ? 'blue-grey' : ''"
          class="flex pa-2">
          <span v-if="printerStatus.printing">Sende Druckauftrag...</span>
          <span v-else-if="printerStatus.status !== null">
            {{ printerStatus.status ? 'Drucker ready' : 'Druckerfehler' }}<br/>
            {{ printerStatus.date.toLocaleDateString() }} 
            {{ printerStatus.date.toLocaleTimeString() }}
          </span>
          <span v-else>Druckerstatus unbekannt</span>              
        </v-alert>        
      </v-card-text>      
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn flat small to="/etiketten/drucken">Neues Etikett</v-btn>
      </v-card-actions>
      </div>
      </v-layout>
    </v-card>      
  </v-flex>
  </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'

  import produktHelperMixin from '@/components/mixins/produkt-helper'

  import * as moment from 'moment'
  moment.locale('de')

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      loadingPrinterStatus: false,
      selection: 'today',
      today: moment().format('YYYY-MM-DD'),
      items: [
        { value: 'today', type: 'moment', enabled: true, after: true },
        { value: 'yesterday', type: 'moment', daysAgo: 1 },
        { value: '2_days_ago', type: 'moment', daysAgo: 2 },
        { value: '3_days_ago', type: 'moment', daysAgo: 3 },
        { value: '4_days_ago', type: 'moment', daysAgo: 4 },
        { value: '5_days_ago', type: 'moment', daysAgo: 5 },
        { value: '6_days_ago', type: 'moment', daysAgo: 6 },
        { divider: true },
        //{ value: 'archive', type: 'moment', text: 'Archiv', daysAgo: 7, before: true, icon: 'fa fa-archive' },
        { value: 'highlighted', type: 'special', text: 'Vorgemerkt', icon: 'attach_file' }        
      ]
    }),
    metaInfo: {
      title: 'Etiketten | Admin'
    },
    computed: {
      alertType () {
        if (this.printerStatus.printing || this.printerStatus.status === null) {
          return 'info'
        }
        return this.printerStatus.status ? 'success' : 'error'
      },
      itemSelection () {
        return this.items.find(item => item.value === this.selection);
      },
      printJobsSelected () {       
        return this.itemSelection
          ? this.filterPrintJobs(this.itemSelection)
          : this.printJobs;
      },
      ...mapGetters('admin/labels', ['printerStatus', 'printJobs'])
    },
    created () {
      this.cleanJobs()
    },
    methods: {
      refreshPrinterStatus () {
        if (!this.loadingPrinterStatus) {
          this.loadingPrinterStatus = true
          this.queryPrinterStatus().finally(() => {
            this.loadingPrinterStatus = false
          })
        }
      },
      formatDate (date, format) {
        return moment(date).format(format)
      },
      momentItem (item) {
        var momentItem = moment(this.today);
        if (item.daysAgo) {
          momentItem = momentItem.subtract(item.daysAgo, 'days')
        }
        return momentItem;
      },
      formatItemTitle (item) {
        if (item.text) {
          return item.text
        } else if (item.type === 'moment') {
          return this.momentItem(item).calendar(this.today, {
            sameDay: '[heute]',
            sameElse: 'L',
            nextDay: '[morgen]',
            nextWeek: 'dddd',
            lastDay: '[gestern]',
            lastWeek: '[letzten] dddd'
          })
        } else {
          return item.value
        }
      },
      formatItemSubTitle (item) {
        const itemPrintJobs = this.filterPrintJobs(item);
        if (itemPrintJobs.length) {
          const labelsTotal = itemPrintJobs.reduce((labels, printJob) => {
            return labels + printJob.data.printOptions.count
          }, 0);
          const asterix = !!itemPrintJobs.find(printJob => !printJob.completed)
          return `${itemPrintJobs.length} Drucke${asterix ? '*' : ''}`//, ${labelsTotal} Etiketten`
        }
      },
      disabledItem (item) {
        if (!item.enabled) {
          return this.filterPrintJobs(item).length === 0
        }
        return false;
      },
      filterPrintJobs (item) {
        if (item && item.type === 'moment') {
          const momentItem = this.momentItem(item);
          return this.printJobs.filter(printJob => {
            const momentPrintJob = moment(printJob.jobId);
            if (item.after) {
              return momentPrintJob.isSameOrAfter(momentItem, 'day');
            } else if (item.before) {
              return momentPrintJob.isSameOrBefore(momentItem, 'day');
            } else {
              return momentPrintJob.isSame(momentItem, 'day')
            }
          })
        } else if (item && item.type === 'special' && item.value === 'highlighted')  {
          return this.printJobs.filter(printJob => printJob.highlighted)
        }
        return [];
      },
      ...mapMutations('admin/labels', ['deleteJob']),
      ...mapActions('admin/labels', ['printJob', 'queryPrinterStatus', 'cleanJobs'])      
    }
  }
</script>