<template>
  <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-card-text class="pb-0">
      <v-text-field
        v-model="search"
        clearable
        label="Suche"></v-text-field>
      <v-select
        v-model="status"
        :items="['aktiv', 'inaktiv', 'mit Meldung', 'neue Meldung', 'kein Produkt', 'fehlerhaft']"
        multiple chips small-chips dense
        :append-outer-icon="`fa fa-${sort}`"
        @click:append-outer="sort = sortItems[(sortItems.indexOf(sort) + 1) % sortItems.length]"
        clearable
        label="Status"></v-select>
    </v-card-text>
    <v-layout align-baseline>
      <v-subheader>Rezepturen</v-subheader>
      <v-spacer></v-spacer>
      <v-btn flat small to="/naehrwerte/rezeptur/anlegen">Anlegen</v-btn>
    </v-layout>    
    <v-list dense subheader class="mb-3" two-line>
      <template v-for="(rezeptur, index) in filteredRezepturen">
        <v-list-tile :key="rezeptur._id" :to="`/naehrwerte/rezeptur/${rezeptur._id}`">
          <v-list-tile-content>
            <v-list-tile-title>{{ rezeptur.title }}</v-list-tile-title>
            <v-list-tile-sub-title v-if="rezeptur.produkte && rezeptur.produkte.length">
              verwendet in {{ rezeptur.produkte.length }} Produkten
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon v-if="rezeptur.active" :color="rezepturStatus(rezeptur._id)">toggle_on</v-icon>
            <v-icon v-else>toggle_off</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider v-if="index !== filteredRezepturen.length - 1"
          :key="`${rezeptur._id}-divider`"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'

  export default {
    data: () => ({
      rezepturen: [],
      naehrwertangaben: [],
      loadingFehlerhaft: false,
      status: [],
      search: null,
      sortItems: ['sort-alpha-down', 'sort-alpha-up', 'sort-numeric-down', 'sort-numeric-up'],
      sort: 'sort-alpha-down'      
    }),
    metaInfo: {
      title: 'Alle Rezepturen',
    },
    computed: {
      filteredRezepturen () {
        var filteredRezepturen = this.rezepturen
          .filter(rezeptur => {
            if (this.status && this.status.length) {
              return this.status.map(status => {
                switch (status) {
                  case 'aktiv': return !!rezeptur.active
                  case 'inaktiv': return !rezeptur.active
                  case 'mit Meldung': return !!rezeptur.meldung
                  case 'neue Meldung': {
                    const neu = new Date(Date.now() - 7 * 24 * 3600 * 1000)
                    return !!(rezeptur.meldung && new Date(rezeptur.meldung.meldungDate) >= neu)
                  }
                  case 'kein Produkt': return !rezeptur.produkte || rezeptur.produkte.length === 0
                  case 'fehlerhaft': {
                    const rezepturNWA = this.naehrwertangaben.find(nwa => nwa.rezeptur === rezeptur._id)
                    return rezepturNWA ? !rezepturNWA.naehrwertangaben : true
                  }
                  default: return false
                }
              }).every(status => status === true)
            }
            return true
          })
          .filter(rezeptur => {
            if (this.search) {
              return (rezeptur.title || '').toLowerCase().includes(this.search.toLowerCase())
            }
            return true
          })


        if (this.sort.startsWith('sort-numeric')) {
          filteredRezepturen = filteredRezepturen.sort((rezepturA, rezepturB) => {
            return new Date(rezepturA.updatedAt).valueOf() - new Date(rezepturB.updatedAt).valueOf()
          })
        }
        if (this.sort === 'sort-alpha-up' || this.sort === 'sort-numeric-up') {
          filteredRezepturen = filteredRezepturen.reverse()
        }

        return filteredRezepturen
      }
    },
    methods: {
      rezepturStatus (rezepturId) {
        const rezeptur = this.rezepturen.find(rezeptur => rezeptur._id === rezepturId)
        const rezepturNWA = this.naehrwertangaben.find(nwa => nwa.rezeptur === rezepturId)
        if (rezeptur && rezepturNWA) {
          if (rezeptur.active && rezepturNWA.naehrwertangaben) {
            return 'info'            
          } else if (rezeptur.active) {
            return rezeptur.produkte && rezeptur.produkte.length ? 'error' : 'warning'
          }
        }
      }
    },
    async beforeRouteEnter (to, from, next) {
      try {
        const rezepturen = await store.dispatch('admin/rezeptur/getRezepturen');
        const naehrwertangaben = await store.dispatch('admin/rezeptur/getNaehrwertangaben');
        next(vm => {
          vm.rezepturen = rezepturen
          vm.naehrwertangaben = naehrwertangaben
        })
      } catch (err) {
        next(false)
      }
    }
  }
</script>