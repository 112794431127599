<template>
  <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-card-title v-if="rezeptur" class="subheading pb-0">
      <v-layout>
        <span>{{ rezeptur.title }}</span>
        <v-spacer></v-spacer>
        <v-icon v-if="rezeptur.active" :color="rezepturStatus">toggle_on</v-icon>
        <v-icon v-else>toggle_off</v-icon>
      </v-layout>      
    </v-card-title>
    <v-subheader>Nährwertangaben</v-subheader>
    <v-card-text class="py-0">
      <v-tabs v-model="activeTabMethodModel" centered grow>
        <v-tab v-for="method in methods" :key="method.key">
          <v-icon v-if="method.name === defaultMethode" class="mr-2">radio_button_checked</v-icon>
          {{ method.text }}       
        </v-tab>
      </v-tabs>
    </v-card-text>
    <v-tabs-items v-if="rezeptur && naehrwertangaben" v-model="activeTabMethodModel">
      <v-tab-item v-for="method in methods" :key="`item-${method.key}`"
        transition="fade" reverse-transition="fade">
        <v-list dense subheader v-if="naehrwertangaben[method.key].data"
          class="rezeptur-details-naehrwertangaben py-3">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>Energie</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.brennwertKJ.formatted }} kJ / {{ naehrwertangaben[method.key].data.brennwertKJ.formattedKcal }} kcal
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>Fett</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.fett.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>davon gesättigte Fettsäuren</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.fettSaturiert.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>Kohlenhydrate</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.carbs.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>davon Zucker</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.carbsZucker.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>Eiweiß</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.eiweiss.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>Salz</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ naehrwertangaben[method.key].data.salz.formatted }} g
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
        <v-card-text v-else>
          <v-alert v-if="!!naehrwertangaben[method.key].error"
            :value="true" type="warning" color="error">
            {{ naehrwertangaben[method.key].error }}
          </v-alert>
          <div v-else>Keine Nährwerte</div>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <v-divider></v-divider>
    <v-layout align-center>
      <v-subheader>Rezeptur</v-subheader>
      <v-spacer></v-spacer>
      <v-btn v-if="rezeptur"
        flat small color="info"
        :to="`/naehrwerte/rezeptur/${rezeptur._id}/bearbeiten`">Bearbeiten</v-btn>
    </v-layout>      
    <v-card-text class="py-0">
      <v-tabs v-model="activeTabRezeptur" centered grow>
        <v-tab>Absolut</v-tab>
        <v-tab>Relativ</v-tab>
      </v-tabs>
    </v-card-text>
    <v-tabs-items v-if="rezeptur" v-model="activeTabRezeptur">
      <v-tab-item transition="fade" reverse-transition="fade">
        <v-list dense subheader
          class="rezeptur-details-lebensmittel py-3">
          <v-list-tile v-for="(lebensmittel, index) in rezeptur.lebensmittel" :key="index">
            <v-list-tile-content>
              <v-list-tile-title>{{ lebensmittel.lm.title }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ lebensmittel.menge }} {{ lebensmittel.mengeEinheit }}
            </v-list-tile-action>
          </v-list-tile>
          <v-card-text class="py-1"><v-divider></v-divider></v-card-text>
          <v-list-tile>
            <v-list-tile-content class="font-weight-bold">
              <v-list-tile-title>
                {{ rezeptur.mengeTotalEinheit === 'g' ? 'Gesamtgewicht' : 'Gesamtvolumen' }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="rezeptur.mengeTotal" class="font-weight-bold">
              {{ rezeptur.mengeTotal }} {{ rezeptur.mengeTotalEinheit }}
            </v-list-tile-action>
            <v-list-tile-action v-else>auto</v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-if="rezepturGewicht && rezeptur.mengeTotal">
            <v-list-tile-content>
              <v-list-tile-title>Differenz</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>{{ rezeptur.mengeTotal - rezepturGewicht }} g</v-list-tile-action>
          </v-list-tile>          
        </v-list>
      </v-tab-item>
      <v-tab-item transition="fade" reverse-transition="fade">
        <v-list dense subheader
          class="rezeptur-details-lebensmittel py-3">
          <v-list-tile v-for="(lebensmittel, index) in rezeptur.lebensmittel" :key="index">
            <v-list-tile-content>
              <v-list-tile-title>{{ lebensmittel.lm.title }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              {{ lebensmittel.anteil * 100 }} %
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-tab-item>
    </v-tabs-items>

    <template v-if="zutatenRequiredList.length">
      <v-subheader>
        <v-layout column>
          <span>Benötigte Lebensmittel</span>
          <small>Zutaten aus Produkten</small>
        </v-layout>
      </v-subheader>
      <v-list dense subheader expand>    
        <template v-for="zutat in zutatenRequiredList">
          <v-list-group
            v-if="zutat.lebensmittel.length > 1"
            :key="zutat._id"
            :value="true">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ zutat.title }}</v-list-tile-title>
                  <v-list-tile-sub-title>Alle Lebensmittel</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-tile
              v-for="lebensmittel in zutat.lebensmittel"
              :key="lebensmittel._id">
              <v-list-tile-content>
                <v-list-tile-title>{{ lebensmittel.title }}</v-list-tile-title>
                <v-list-tile-sub-title>ID: {{ lebensmittel._id }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn :to="`/naehrwerte/lebensmittel/${lebensmittel._id}`" icon>
                  <v-icon>menu_book</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>

          <v-list-tile
            v-else-if="zutat.lebensmittel.length === 1"
            :key="zutat._id">
            <v-list-tile-content>
              <v-list-tile-title>{{ zutat.lebensmittel[0].title }}</v-list-tile-title>
              <v-list-tile-sub-title>ID: {{ zutat.lebensmittel[0]._id }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn :to="`/naehrwerte/lebensmittel/${zutat.lebensmittel[0]._id}`" icon>
                <v-icon>menu_book</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>

          <v-list-tile
            v-else
            :key="zutat._id">
            <v-list-tile-content>
              <v-list-tile-title>{{ zutat.title }}</v-list-tile-title>
              <v-list-tile-sub-title class="error--text">nicht vorhanden</v-list-tile-sub-title>            
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn :to="{ path: '/naehrwerte/lebensmittel/anlegen', query: { title: zutat.title, zutat: zutat._id }}" icon>
                <v-icon>create</v-icon>                
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>        
        </template>
      </v-list>
    </template>

    <template v-if="produkte.length">
      <v-subheader>Produkte</v-subheader>
      <v-card-text v-for="produkt in produkte" :key="produkt._id" class="caption pt-0">
        <div class="font-weight-bold">{{ produkt.title}}</div>
        <div class="grey--text">{{ formatZutaten(produkt.zutaten, { details: false }) }}</div>
      </v-card-text>
      </template>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import store from '@/store'

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      rezeptur: null,
      produkte: [],
      produktZutatLebensmittel: [],
      naehrwertangaben: null,
      methods: [
        { name: 'rezeptur+zutaten', key: 'avg', text: 'Mittel'},
        { name: 'rezeptur', key: 'rezeptur', text: 'Absolut'},
        { name: 'zutaten', key: 'zutaten', text: 'Relativ'}
      ],
      activeTabMethod: null,
      activeTabRezeptur: null
    }),
    metaInfo: {
      title: 'Nährwertangaben | Admin',
      titleTemplate: null
    },
    computed: {
      zutatenRequiredList () {
        return [].concat(...this.produkte.map(produkt => {
          return produkt.zutaten.map(produktZutatWrapper => produktZutatWrapper.zutat)
        })).reduce((produktZutaten, produktZutat) => {
          if (!produktZutaten.find(produktZutatAdded => produktZutatAdded._id === produktZutat._id)) {
            produktZutaten.push({
              ...produktZutat,
              lebensmittel: this.produktZutatLebensmittel.filter(lebensmittel => {
                return lebensmittel.parent === produktZutat._id
              })
            });
          }
          return produktZutaten;
        }, []).filter(produktZutat => {
          if (this.rezeptur) {
            return !produktZutat.lebensmittel.some(zutatLebensmittel => {
              return this.rezeptur.lebensmittel
                .map(lebensmittelWrapper => lebensmittelWrapper.lm)
                .some(rezepturLebensmittel => {
                  return rezepturLebensmittel._id === zutatLebensmittel._id
                })
            })
          }
          return true
        })
      },      
      defaultMethode () {
        if (this.naehrwertangaben && this.naehrwertangaben.default.data) {
          // Default meaning displayed to user (prefMethod==null)
          return this.naehrwertangaben.default.data.methode
        }
      },
      activeTabMethodModel: {
        get () {
          if (this.rezeptur && this.naehrwertangaben) {
            const preselectedTab = this.defaultMethode
              ? this.methods.findIndex(method => method.name === this.defaultMethode)
              : this.methods.findIndex(method => method.name === this.rezeptur.prefMethode)
            return this.activeTabMethod === null && preselectedTab !== -1
              ? preselectedTab
              : this.activeTabMethod
          }
          return this.activeTabMethod
        },
        set (index) {
          this.activeTabMethod = index
        }
      },
      rezepturStatus () {
        if (this.rezeptur && this.naehrwertangaben) {
          if (this.rezeptur.active && this.naehrwertangaben.default.data) {
            return 'info'            
          } else if (this.rezeptur.active) {
            return this.produkte.length ? 'error' : 'warning'
          }
        }
      },
      rezepturGewicht () {
        if (this.rezeptur && this.rezeptur.mengeTotalEinheit === 'g') {
          return this.rezeptur.lebensmittel.reduce((rezepturGewicht, lebensmittel) => {
            if (lebensmittel.mengeEinheit === 'g') {
              return rezepturGewicht + lebensmittel.menge
            } else if (lebensmittel.mengeEinheit === 'ml') {
              return rezepturGewicht + lebensmittel.menge * lebensmittel.lm.volumeToWeightFactor
            }
          }, 0)
        }        
      }
    },
    async beforeRouteEnter ({ params: { rezepturId } }, from, next) {
      try {
        const responseData = await store.dispatch('admin/rezeptur/findRezeptur', rezepturId);
        next(vm => {
          vm.rezeptur = responseData.rezeptur
          vm.produkte = responseData.produkte
          vm.produktZutatLebensmittel = responseData.produktZutatLebensmittel
          vm.naehrwertangaben = responseData.naehrwertangaben
        })
      } catch (err) {
        next('/404')
      }
    },
    async beforeRouteUpdate ({ params: { rezepturId } }, from, next) {
      try {
        const responseData = await store.dispatch('/admin/rezeptur/findRezeptur', rezepturId);        
        this.rezeptur = responseData.rezeptur
        this.produkte = responseData.produkte
        this.produktZutatLebensmittel = responseData.produktZutatLebensmittel        
        this.naehrwertangaben = responseData.naehrwertangaben
        next()
      } catch (err) {
        next('/404')
      }
    }
  }
</script>

<style>
  #rezeptur-details-exp-panel .v-expansion-panel__header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  .rezeptur-details-naehrwertangaben .v-list__tile,
  .rezeptur-details-lebensmittel .v-list__tile {
    height: auto !important;
  }    
</style>