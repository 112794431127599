<template>
  <component :is="children.length ? 'v-list-group' : 'div'">
    <v-list-tile slot="activator" avatar>
      <v-list-tile-action @click.stop>
        <v-menu offset-y>
          <v-icon slot="activator">menu</v-icon>
          <v-list dense>
            <v-list-tile @click="showAddDialog">
              <v-list-tile-title>Hinzufügen</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="showEditDialog">
              <v-list-tile-title>Ändern</v-list-tile-title>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile @click="dialogDelete = true">
              <v-list-tile-title>Löschen</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>
          {{ value.title }}
        </v-list-tile-title>
        <v-list-tile-sub-title>
          <v-breadcrumbs class="pa-0">
            <v-breadcrumbs-item
              v-for="ancestor in value.ancestors"
              :key="ancestor"
              disabled
              class="caption"
            >
              {{ ancestor }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
    <draggable v-model="children">
      <node v-for="child in children" :key="child._id" :value="child"></node>
    </draggable>
    <v-dialog v-model="dialogAdd" max-width="500">
      <v-card>
        <v-card-title>
          <h3>{{value.title}}<br/><small>Neue Subkategorie</small></h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formAdd" @submit.prevent>
            <v-text-field v-model="formAdd.title" label="Name" required :disabled="loading"></v-text-field>
            <v-text-field v-model="formAdd.alias" label="Alias" :disabled="loading"></v-text-field>
            <v-select
              v-model="formAdd.produkttypMain"
              :items="produkttypen"
              label="Produkttyp"
              dense offset-y
              :disabled="loading"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogAdd = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formAddCategory" :disabled="loading">Hinzufügen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="500">
      <v-card>
        <v-card-title>
          <h3>{{value.title}} bearbeiten</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formEdit" @submit.prevent>
            <v-text-field v-model="formEdit.title" label="Name" required :disabled="loading"></v-text-field>
            <v-text-field v-model="formEdit.alias" label="Alias" required :disabled="loading"></v-text-field>
            <v-select
              v-model="formEdit.produkttypMain"
              :items="produkttypen"
              label="Produkttyp"
              dense offset-y
              :disabled="loading"
            ></v-select>            
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogEdit = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formEditCategory" :disabled="loading">Speichern</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <v-card-text class="subheading">
          <strong>{{ value.title }}</strong> wirklick löschen?
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogDelete = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formDeleteCategory" :disabled="loading">Löschen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
  </component>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import draggable from 'vuedraggable'

  export default {
    data: () => ({
      loading: false,
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      formAdd: { title: '', alias: '', produkttypMain: null },
      formEdit: { title: '', alias: '', produkttypMain: null }
    }),
    name: 'node',
    props: ['value'],
    computed: {
      children: {
        get () {
          return this.value.children
        },
        set (children) {
          this.updateOrder({
            categories: children.map((child, index) => ({ ...child, order: index }))
          })
        }
      },
      ...mapGetters('admin/products', ['produkttypen'])
    },
    methods: {
      ...mapActions('admin/categories', ['createCategory', 'updateOrder', 'updateCategory', 'deleteCategory']),
      showAddDialog () {
        this.formAdd.title = ''
        this.formAdd.alias = ''
        this.formAdd.produkttypMain = null
        this.dialogAdd = true
      },
      showEditDialog () {
        this.formEdit.title = this.value.title
        this.formEdit.alias = this.value.alias
        this.formEdit.produkttypMain = this.value.produkttypMain
        this.dialogEdit = true
      },
      formAddCategory () {
        if (this.$refs.formAdd.validate()) {
          this.loading = true
          this.createCategory({
            title: this.formAdd.title,
            ancestors: [...this.value.ancestors, this.value.alias],
            alias: this.formAdd.alias || this.formAdd.title.replace(/\W+/g, '-').toLowerCase(),
            produkttypMain: this.formAdd.produkttypMain,
            parent: this.value._id,
            order: this.value.children.length
          }).then(() => {
            this.dialogAdd = false
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formEditCategory () {
        if (this.$refs.formEdit.validate()) {
          this.loading = true
          this.updateCategory({
            _id: this.value._id,
            title: this.formEdit.title,
            alias: this.formEdit.alias,
            produkttypMain: this.formEdit.produkttypMain
          }).then(() => {
            this.dialogEdit = false
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formDeleteCategory () {
        this.loading = true
        this.deleteCategory(this.value._id).then(() => {
          this.dialogRemove = false
        }).finally(() => {
          this.loading = false
        })
      }
    },
    components: {
      draggable
    }
  }
</script>