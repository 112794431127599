<template>
  <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-form ref="formRezepturRef" @submit.prevent>
    <v-card-text class="pb-0">
      <v-text-field
        v-model.trim="rezeptur.title"
        required
        :hint="rezeptur._id ? `ID: ${rezeptur._id}`: ''"
        :persistent-hint="!!rezeptur._id"
        label="Name"></v-text-field>
    </v-card-text>
    <v-layout align-baseline>
      <v-subheader>Lebensmittel</v-subheader>
      <v-spacer></v-spacer>
      <v-btn small flat @click="addLebensmittel">Hinzufügen</v-btn>
    </v-layout>    
    <v-list v-if="rezeptur && rezeptur.lebensmittel.length" 
      dense subheader class="pb-3">
      <v-list-tile v-for="(lebensmittel, index) in rezeptur.lebensmittel" :key="index"
        @click="editLebensmittel(index)">
        <v-list-tile-content>
          <v-list-tile-title>{{ lebensmittel.lm.title }}</v-list-tile-title>
          <v-list-tile-sub-title>
            <v-layout>
              <v-flex xs6>Menge: {{ lebensmittel.menge }} {{ lebensmittel.mengeEinheit }}</v-flex>
              <v-flex xs6>Anteil: {{ lebensmittel.anteil * 100 }} %</v-flex>
            </v-layout>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action @click.stop>
          <v-btn :to="`/naehrwerte/lebensmittel/${lebensmittel.lm._id}`" icon>
            <v-icon>menu_book</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <v-card-text class="py-0">
      <v-layout>
        <v-flex xs8 class="pr-1">
          <v-text-field
            v-model.number="mengeTotalModel"
            clearable
            type="number"
            placeholder="Automatisch"
            label="Gesamtmenge"></v-text-field>
        </v-flex>
        <v-flex xs4 class="pl-1">
          <v-select
            v-model="rezeptur.mengeTotalEinheit"
            required
            :items="['g', 'ml']"
            label="Einheit"
            dense
            menu-props="offsetY, allowOverflow"></v-select>
        </v-flex>
      </v-layout>
      <v-select
        v-model="prefMethodeModel"
        :items="methods"
        clearable
        label="Methode"
        placeholder="Automatisch"
        dense
        menu-props="offsetY, allowOverflow"></v-select>
      <v-switch v-model="rezeptur.active" label="Aktivieren" class="mt-0"></v-switch>
    </v-card-text>
    </v-form>
    <v-expansion-panel id="rezeptur-edit-exp-panel" class="elevation-0">
      <v-expansion-panel-content :disabled="produkte.length === 0">
        <template v-slot:header>
          <v-subheader>Verwendet in {{ produkte.length }} Produkten</v-subheader>
        </template>
        <v-list dense subheader>
          <v-list-tile v-for="produkt in produkte" :key="produkt._id"
            :to="`/katalog/${produkt.slug}/bearbeiten`">
            <v-list-tile-content>
              <v-list-tile-title>{{ produkt.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>    
    <v-divider></v-divider>
    <v-card-text>
      <v-layout justify-space-around>
        <v-btn color="error" @click="formDelete"
          :loading="loadingDelete"
          :disabled="!rezeptur._id || !!produkte.length ">Löschen</v-btn>
        <v-btn color="success" @click="formSubmit"
          :loading="loadingSave">Speichern</v-btn>
      </v-layout>
    </v-card-text>
    <v-dialog
      v-model="dialogLebensmittelShow"
      :transition="false"
      :persistent="true"
      max-width="500"
      scrollable>       
      <v-card>
        <v-card-title class="subheading">
          Lebensmittel {{ dialogLebensmittelIndex !== -1 ? 'bearbeiten' : 'hinzufügen' }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-switch v-model="filterLebensmittelList"
            class="mt-0"
            label="Lebensmittel nach Produkten filtern"></v-switch>
          <v-select
            v-if="filterLebensmittelList"
            v-model="formLebensmittel.lm"
            :items="produktZutatLebensmittel"
            item-text="title"
            item-value="_id"
            return-object
            label="Lebensmittel (Produkte)"
            cache-items
            clearable
            dense
            menu-props="offsetY, allowOverflow"></v-select>
          <v-autocomplete
            v-else
            v-model="formLebensmittel.lm"
            :items="lebensmittelList"
            item-text="title"
            item-value="_id"
            return-object
            label="Lebensmittel (alle)"
            cache-items
            clearable
            dense
            menu-props="offsetY, allowOverflow"></v-autocomplete>
          <v-layout wrap>
            <v-flex xs8 class="pr-1">
              <v-text-field
                v-model.number="formLebensmittel.menge"
                type="number"
                label="Menge"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-select
                v-model="formLebensmittel.mengeEinheit"
                required
                :items="['g', 'ml']"
                label="Einheit"
                dense
                menu-props="offsetY, allowOverflow"></v-select>
            </v-flex>
            <v-flex xs8 class="pr-1">
              <v-text-field
                v-model.number="formLebensmittel.anteil"
                type="number"
                label="Anteil"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn             
            small flat @click="formLebensmittelDelete" icon
            color="error"
            :disabled="dialogLebensmittelIndex === -1">
            <v-icon>delete</v-icon></v-btn>
          <v-btn             
            small flat @click="dialogLebensmittelShow = false">
            Abbrechen</v-btn>
          <v-btn 
            @click.native="formLebensmittelSave" :disabled="formLebensmittel.lm == null"
            small flat color="primary">
            {{ dialogLebensmittelIndex === -1 ? 'Hinzufügen' : 'Speichern' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'

  const emptyRezeptur = {
    title: 'Neues Rezeptur',
    lebensmittel: [],
    mengeTotal: 0,
    mengeTotalEinheit: 'g',
    prefMethode: null,
    active: false,
  }

  const emptyLebensmittel = {
    lm: null,
    menge: null,
    mengeEinheit: 'g',
    anteil: null
  }

  export default {
    data: () => ({
      rezeptur: JSON.parse(JSON.stringify(emptyRezeptur)),
      produkte: [],
      produktZutatLebensmittel: [],
      naehrwertangaben: null,
      lebensmittelList: [],
      methods: [
        { value: 'rezeptur+zutaten', key: 'avg', text: 'Mittel'},
        { value: 'rezeptur', key: 'rezeptur', text: 'Absolut'},
        { value: 'zutaten', key: 'zutaten', text: 'Relativ'}
      ],
      loadingSave: false,
      loadingDelete: false,
      dialogLebensmittelIndex: -1,
      dialogLebensmittelShow: false,
      filterLebensmittelList: true,
      formLebensmittel: JSON.parse(JSON.stringify(emptyLebensmittel)),
    }),
    metaInfo: {
      title: 'Rezeptur bearbeiten',
    },
    computed: {
      prefMethodeModel: {
        get () {
          return this.rezeptur.prefMethode
        },
        set (methode) {
          console.log(methode)
          // Set null (not undefined) if select is cleared
          // undefined will not delete the rezeptur server-side
          this.rezeptur.prefMethode = methode ? methode : null
        }
      },
      mengeTotalModel: {
        get () {
          return this.rezeptur.mengeTotal > 0 ? this.rezeptur.mengeTotal : null
        },
        set (mengeTotal) {
          return this.rezeptur.mengeTotal = mengeTotal
        }
      },
      loading () {
        return this.loadingSave || this.loadingDelete
      }
    },
    async beforeRouteEnter ({ params: { rezepturId } }, from, next) {
      try {
        const lebensmittelList = await store.dispatch('admin/rezeptur/getLebensmittel');
        if (rezepturId) {
          const responseData = await store.dispatch('admin/rezeptur/findRezeptur', rezepturId);
          return next(vm => {
            vm.rezeptur = responseData.rezeptur
            vm.produkte = responseData.produkte
            vm.produktZutatLebensmittel = responseData.produktZutatLebensmittel
            vm.naehrwertangaben = responseData.naehrwertangaben
            vm.lebensmittelList = lebensmittelList
          })
        }
        next(vm => {
          vm.lebensmittelList = lebensmittelList
        })
      } catch (err) {
        return next('/404')
      }
    },
    async beforeRouteUpdate ({ params: { rezepturId } }, from, next) {
      try {
        this.lebensmittelList =  await store.dispatch('admin/rezeptur/getLebensmittel');        
        if (rezepturId) {
          const responseData = await store.dispatch('admin/rezeptur/findRezeptur', rezepturId);
          this.rezeptur = responseData.rezeptur
          this.produkte = responseData.produkte
          this.produktZutatLebensmittel = responseData.produktZutatLebensmittel        
          this.naehrwertangaben = responseData.naehrwertangaben
        } else {
          this.rezeptur = JSON.parse(JSON.stringify(emptyRezeptur))
          this.produkte = []
          this.produktZutatLebensmittel = []
          this.naehrwertangaben = null
        }
      } catch (err) {
        return next('/404')
      }
      next()
    },
    methods: {
      formLebensmittelSave () {
        const formLebensmittelCopy = JSON.parse(JSON.stringify(this.formLebensmittel))
        if (this.dialogLebensmittelIndex === -1) {
          this.rezeptur.lebensmittel.push(formLebensmittelCopy)
        } else {
          this.$set(this.rezeptur.lebensmittel, this.dialogLebensmittelIndex, formLebensmittelCopy)
        }
        this.dialogLebensmittelShow = false
        this.resetFormLebensmittel()
      },
      formLebensmittelDelete () {
        if (this.dialogLebensmittelIndex !== -1) {
          this.rezeptur.lebensmittel.splice(this.dialogLebensmittelIndex, 1)
        }
        this.dialogLebensmittelShow = false
        this.resetFormLebensmittel()
      },
      editLebensmittel(index) {
        this.dialogLebensmittelIndex = index
        this.formLebensmittel = JSON.parse(JSON.stringify(this.rezeptur.lebensmittel[index]))
        this.dialogLebensmittelShow = true
      },
      addLebensmittel() {
        this.resetFormLebensmittel()
        this.dialogLebensmittelShow = true
      },
      resetFormLebensmittel () {
        this.dialogLebensmittelIndex = -1
        this.formLebensmittel = JSON.parse(JSON.stringify(emptyLebensmittel))
      },
      formSubmit () {
        if (!this.loading && this.$refs.formRezepturRef.validate()) {
          this.loadingSave = true         
          const fn = this.rezeptur._id ? this.updateRezeptur : this.createRezeptur
          fn(this.rezeptur).then(rezeptur => {
            this.$router.push(`/naehrwerte/rezeptur/${rezeptur._id}`)
          }).finally(() => {
            this.loadingSave = false
          })
        }
      },
      formDelete () {
        if (!this.loading && !!this.rezeptur._id) {
          this.loadingDelete = true
          this.deleteRezeptur(this.rezeptur._id).then(() => {
            this.$router.push('/naehrwerte/rezeptur')
          }).finally(() => {
            this.loadingDelete = false
          })
        }
      },
      ...mapActions('admin/rezeptur', ['createRezeptur', 'updateRezeptur', 'deleteRezeptur']),      
    }
  }
</script>

<style>
  #rezeptur-edit-exp-panel .v-expansion-panel__header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
</style>