<template>
  <v-container fill-height>
    <v-layout justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="fill-height">
          <v-card-title primary-title>
            <h3 class="title">Verfügbarkeit <small>aktuell lieferbar</small></h3>
          </v-card-title>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="produkteSearch"              
            label="Suche"
            hide-details
            prepend-inner-icon="search"
            single-line
            clearable>
          </v-text-field>
          <v-checkbox
            v-model="showAll"
            label="Alle anzeigen">
          </v-checkbox>
          <template v-for="(produkt, index) in produkteKatalogFiltered" >
            <div :key="produkt._id" class="d-flex align-center">
            <v-switch dense hide-details class="mt-0" style="flex-grow: 0 !important"
              @change="value => changeInventory(produkt, value)"
              :disabled="loading"
              :input-value="produktInventory(produkt)"></v-switch>
            {{ produkt.title }}
            </div>
            <v-divider class="my-1" :key="index"></v-divider>
          </template>          
          <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    metaInfo: {
      title: 'Produktverfügbarkeit',
    },
    data: () => ({
      produkteSearch: null,
      showAll: true,
      loading: false
    }),
    created() {
      this.loadProdukte();
    },
    methods: {
      produktInventory(produkt) {
        return (produkt.skus || []).some(sku => sku.inventory > 0)
      },
      changeInventory(produkt, newValue) {        
        this.loading = true
        this.updateProduktAvailability({ produkt, stock: newValue ? 1 : 0}).then(()=> {
          this.loadProdukte()
        }).finally(() => this.loading = false)
      },
      ...mapActions('products', ['loadProdukte']),
      ...mapActions('admin/products', ['updateProduktAvailability'])
    },
    computed:{
      produkteKatalogFiltered() {
        return (this.produkteKatalog || []).filter(produkt => {
          if (!this.showAll && this.produktInventory(produkt)) {
            return false;
          }
          if (this.produkteSearch && this.produkteSearch.length >= 2) {
            const terms = this.produkteSearch
              ? this.produkteSearch.trim().toLowerCase().split(/[\s,]+/)
              : []
            for (let term of terms) {
              if (!produkt.title.toLowerCase().includes(term)) {
                if (!produkt.titleAlt.toLowerCase().includes(term)) {
                  // Skip this produkt
                  return false
                }
              }
            }
          }
          return true;
        })
      },
      ...mapGetters('products', ['produkttypen', 'produkttypFriendly', 'produkteKatalog'])
    }
  }
</script>