<template>
  <v-container fill-height>
    <v-layout justify-center fill-height>
    <v-flex xs12 sm8 md6>
    <v-card class="fill-height">
      <v-form>
      <v-layout column fill-height>      
        <v-toolbar card :extended="!!produkt">
          <v-menu offset-y>
            <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
            <v-list dense>
              <v-list-tile @click.prevent="resetEtikett">
                <v-list-tile-title>Zurücksetzen</v-list-tile-title>
              </v-list-tile>
              <v-list-tile 
                @click.capture="skipRouteLeaveChecks = true"
                to="/etiketten/drucken" exact>
                <v-list-tile-title>Neues Etikett</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-toolbar-title>
            <span>{{ !!produkt ? 'Produktetikett' : 'Neues Etikett' }}</span>
          </v-toolbar-title>
          <template v-if="!!produkt" slot="extension">
            <span class="subheading font-weight-bold">{{ produkt.title }}</span>
          </template>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-textarea
            ref="formTitleRef"
            v-model.trim="titleModel"            
            :rows="1"
            auto-grow counter
            @keypress="preventNewLine"
            :hint="longTitleHint"
            :persistent-hint="!!(vorlageSizeModel && vorlageSizeModel.supportedOptionsMask & vorlageOpts.FLAG_LONG_TITLE)"
            label="Titel">
            <template slot="append"              
              v-if="vorlageSizeModel && vorlageSizeModel.supportedOptionsMask & vorlageOpts.FLAG_LONG_TITLE">
              <svg @click.stop.prevent="optionsMaskModel = optionsMaskModel ^ vorlageOpts.FLAG_LONG_TITLE"
                style="display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -0.125em; width: 1.25em; font-size: 1.5em; transform: scale(-1, 1); cursor: pointer"
                aria-hidden="true" focusable="false" data-prefix="fad" data-icon="text-size" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                class="svg-inline--fa fa-text-size fa-w-20">
                  <g class="fa-group">
                    <path :style="{opacity: optionsMaskModel & vorlageOpts.FLAG_LONG_TITLE ? 1 : 0.4}" class="fa-secondary" fill="currentColor" d="M320 240v64a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-16h-56v128h24a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H96a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h24V288H64v16a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-64a16 16 0 0 1 16-16h288a16 16 0 0 1 16 16z"></path>
                    <path :style="{opacity: optionsMaskModel & vorlageOpts.FLAG_LONG_TITLE ? 0.4 : 1}" class="fa-primary" fill="currentColor" d="M640 48v96a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32h-88v304h40a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H368a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h40V112h-88v32a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h352a16 16 0 0 1 16 16z"></path>
                  </g>
              </svg>
            </template>
          </v-textarea>
          <v-layout class="py-1"
            v-if="vorlageModel && vorlageModel.autoTitle">
            <v-btn @click.native="dialogTitleShow" flat small>Automatischer Titel</v-btn>
          </v-layout>
          <v-layout>
            <v-flex xs8 class="pr-1">
              <v-select
                ref="formVorlageRef"
                :items="vorlagenListItems"
                item-value="text"
                dense
                menu-props="offsetY, allowOverflow"
                v-model="vorlageIdModel"
                :error-messages="!vorlagenListItems.length && !!produkt ? `Keine Vorlage für ${produkttypFriendly(produkt.produkttyp)}` : []"
                label="Vorlage"></v-select>
              </v-flex>
            <v-flex xs4 class="pl-1">
              <v-select
                ref="formVorlageSizeRef"
                :items="vorlageModelSizeItems"
                item-value="text"
                dense
                menu-props="offsetY, allowOverflow"
                :error="!!vorlageSizeModel && vorlageSizeModel.lmiv && vorlageSizeItemDisabled(vorlageSizeModel)"
                v-model="vorlageSizeIdModel"
                :hint="vorlageSizeModel ? vorlageSizeModel.description : ''"
                :persistent-hint="!!vorlageSizeModel">
                <label slot="label">Größe
                  <span v-if="etikett.vorlage.sizeId !== null">&middot;
                    <span class="font-italic">gesetzt</span>
                  </span>
                </label>
              </v-select>
            </v-flex>
          </v-layout>
          <v-text-field
            ref="formHergestelltAusRef"
            v-if="vorlageModel && vorlageModel.specialFields.includes('hergestelltAus')"
            v-model.trim="hergestelltAusModel"
            label="Hergestellt aus"></v-text-field>
          <v-text-field
            ref="formFruchtanteilRef"
            v-if="vorlageModel && vorlageModel.specialFields.includes('fruchtanteil')"
            v-model.number="fruchtanteilModel"
            type="number" suffix="%">
            <template slot="append-outer">
              <v-icon 
                @click.prevent="fruchtanteilModel = Math.max(0, fruchtanteilModel - 1)"
                class="mx-1"
                medium>indeterminate_check_box</v-icon>
              <v-icon
                @click.prevent="fruchtanteilModel = fruchtanteilModel + 1"
                class="mx-1"
                medium>add_box</v-icon>
            </template>
            <label slot="label">Fruchtanteil
              <span v-if="produktFruchtanteilChanged">&middot;
                <span class="font-italic">geändert</span>
              </span>
            </label>
          </v-text-field>
        </v-card-text>
        <v-subheader>
          <v-layout align-baseline>
            <span>Zutaten</span>
            <v-spacer></v-spacer>
            <small v-if="produktZutatenChanged" class="font-italic">geändert</small>
          </v-layout>
        </v-subheader>
        <v-card-text class="py-0">
          <!--
            https://medium.com/vuetify/drag-n-drop-in-vuetify-part-ii-2b07b4b27684
            https://github.com/vuetifyjs/vuetify/issues/2234
          -->
          <v-data-table
            ref="dTable"
            :items="zutatenModel"
            hide-headers
            hide-actions>
            <template slot="items" slot-scope="props">
              <!-- NOTE:  You'll need a unique ID, that is specific to the given item, for the key.   Not providing a unique key that's bound to the item object will break drag and drop sorting.  The itemKey method will return a uid for a given object using WeakMap.  You could just use a property in the object with a unique value, like "props.item.name" in this case, but often getting a unique value from the object's properties can be difficult, like when adding new rows, or when the unique field is open to editing, etc. -->
              <tr :key="zutatKey(props.item)">
                <td class="handle px-2 py-1" style="width: 24px; cursor: pointer;">
                  <v-icon>menu</v-icon>
                </td>
                <td 
                  @click.prevent="dialogZutatShow(props.index)"
                  class="px-2 py-1">
                  <div>{{ props.item.zutat.title }}</div>
                  <div 
                    v-if="props.item.zutat.details"
                    class="grey--text text-darken-1">
                    {{ props.item.zutat.details }}</div>
                </td>
                <td class="px-2 py-1 text-align-right" style="width: 48px" role="button">
                  <!-- returnValue is set once initially, updated on save() and synced on close --> 
                  <v-edit-dialog
                    :ref="`dialogZutatAnteilRef${props.index}`"
                    @open="setFormZutatIndexDelayed(props.index)"
                    @close="formZutatSave"
                    lazy>
                    {{ props.item.anteil ? `${props.item.anteil} %` : 'k.A.' }}
                    <v-text-field
                      slot="input"
                      v-model.number="formZutat.anteil"
                      label="Anteil"
                      type="number"
                      autofocus
                      single-line>
                    </v-text-field>
                  </v-edit-dialog>                                    
                </td>
              </tr>
            </template>
            <template slot="footer">
              <tr style="font-size: 13px">
                <td colspan="3" class="px-0 py-1">
                  <v-btn 
                    @click.native="dialogZutatShow()"
                    small flat>Zutat hinzufügen</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-textarea
            v-show="false"
            :rows="1"
            :value="preview ? formatZutaten(zutatenModel) : ''"
            label="Vorschau Zutatenliste"
            auto-grow single-line readonly>
            <template v-slot:prepend-inner>
              <v-icon @click.capture="preview = !preview">
                {{ preview ? 'check_box' : 'check_box_outline_blank' }}
              </v-icon>
            </template>
          </v-textarea>      
        </v-card-text>
        <v-expansion-panel v-if="produkt"
          id="etiketten-druck-exp-panel" class="elevation-0"
          :value="expPanelOpenedModel ? 0 : null"
          @input="expPanelOpenedModel = $event === 0 ? true : false">
          <v-expansion-panel-content>
            <template v-slot:header>
              <v-subheader>Nährwertangaben</v-subheader>
            </template>
            <v-list v-if="naehrwertangaben"
              id="etiketten-druck-naehrwertangaben"
              dense subheader>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Energie</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.brennwertKJ.formatted }} kJ / {{ naehrwertangaben.brennwertKJ.formattedKcal }} kcal
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Fett</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.fett.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>davon gesättigte Fettsäuren</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.fettSaturiert.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Kohlenhydrate</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.carbs.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>davon Zucker</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.carbsZucker.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Eiweiß</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.eiweiss.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Salz</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ naehrwertangaben.salz.formatted }} g
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <v-card-text class="py-0">
              <v-btn :disabled="!produkt" @click="dialogMeldungShow" small flat>
                {{ naehrwertangaben ? 'Neue Rezeptur melden' : 'Rezeptur melden' }}
              </v-btn>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>           
        <v-card-text class="pt-0">
          <v-textarea
            ref="formFreitextRef"
            v-model="freitextModel"
            rows="1" auto-grow
            label="Freitext"></v-textarea>
          <v-select
            ref="formQrCodeRef"          
            v-if="vorlageModel && vorlageModel.specialFields.includes('qrCode')"
            :items="qrCodeItems"
            item-value="text"            
            dense
            menu-props="offsetY, allowOverflow"
            v-model="qrCodeModel"
            label="QRCode"></v-select>
          <v-layout>
            <v-flex xs6 class="pr-1">
              <v-dialog
                ref="dialogMhdRef"
                v-model="dialogMhd"
                :return-value.sync="mhdModel"
                persistent
                lazy
                full-width
                width="290px">
                <v-text-field 
                  slot="activator"
                  :value="mhdModelFormatted"
                  label="MHD"
                  readonly></v-text-field>
                <v-date-picker
                  v-model="mhdModel"
                  scrollable
                  :first-day-of-week="1">
                  <v-btn small flat @click.native="dialogMhd = false">Abbrechen</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn small flat color="primary" @click.native="$refs.dialogMhdRef.save(mhdModel)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs6 class="pl-1">
              <v-text-field 
                ref="formFuellMengeRef"
                v-model.number="fuellMengeModel"
                label="Füllmenge" type="number" :suffix="fuellMengeUnitModel"></v-text-field>
            </v-flex>            
          </v-layout>
          <v-text-field 
            ref="formCountRef"
            v-model.number="countModel"
            label="Anzahl" type="number">
            <template slot="append-outer">
              <v-icon 
                @click.prevent="countModel = Math.max(0, countModel - 1)"
                class="mx-1"                
                medium>indeterminate_check_box</v-icon>
              <v-icon
                @click.prevent="countModel = countModel + 1"
                class="mx-1"                
                medium>add_box</v-icon>
            </template>
          </v-text-field>
        </v-card-text>      
        <v-card-text class="pt-0">
          <v-layout justify-space-around>
            <v-btn 
              @click.native="formEtikettSubmit(false)"
              color="info">
              <v-icon left>attach_file</v-icon>Merken</v-btn>
            <v-btn 
              @click.native="formEtikettSubmit(true)"
              color="primary" 
              :disabled="!vorlageModel || (!!vorlageSizeModel && vorlageSizeModel.lmiv && !naehrwertangaben)">
              <v-icon left>print</v-icon>Drucken
            </v-btn>            
          </v-layout>
          <div class="mt-3 caption"
            v-if="!!vorlageSizeModel && vorlageSizeModel.lmiv && produkt && !naehrwertangaben">
            <strong>Keine Nährwertangaben vorhanden</strong>
            <ol type="A">
              <li>
                Zurückstellen
                <ol>
                  <li>Rezeptur melden</li>
                  <li>Etikett merken und später drucken</li>
                </ol>
              </li>
              <li>
                Jetzt drucken
                <ol>
                  <li>Größe ändern (z.B. mittel)</li>
                  <li>Ohne Nährwertangaben drucken</li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="mt-3 caption"
            v-else-if="!!vorlageSizeModel && vorlageSizeModel.lmiv && produkt && produktChanged">
            <strong v-if="produktZutatenChanged"
              class="error--text">Zutatenliste passt nicht zu Nährwertangaben</strong>
            <strong v-else-if="produktFruchtanteilChanged"
            class="error--text">Fruchtanteil passt nicht zu Nährwertangaben</strong>
            <ol type="A">
              <li v-if="produktZutatenChanged">Neues Produkt?</li>
              <li>Ohne Nährwertangaben drucken</li>
              <li>Trotzdem drucken</li>
            </ol>
          </div>          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat small
            @click.capture="skipRouteLeaveChecks = true"
            to="/etiketten" exact>Zur Übersicht</v-btn>
        </v-card-actions>        
      </v-layout>
      </v-form>
    </v-card>
    <v-dialog
      v-model="dialogTitle"
      :transition="false"
      max-width="500"
      scrollable>       
      <v-card>
        <v-card-title>
          <h3>Neuer Titel</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="pt-0">
          <v-textarea
            :value="autoTitleModel"
            hide-details single-line rows="1" auto-grow
            readonly></v-textarea>
        </v-card-title>
        <v-divider></v-divider>
        <v-subheader>Fruchtaufstrich</v-subheader>
        <v-list dense subheader>
          <draggable
            v-model="formTitle.main"
            handle=".drag-handle" group="title"
            style="min-height: 10px;">
            <!-- Unique key: Assume same zutat appears only once -->
            <v-list-tile v-for="item in formTitle.main" :key="item.title">
              <v-list-tile-action class="drag-handle" @click.stop>
                <v-icon color="grey darken-3">menu</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.titleShort }}</v-list-tile-title>
                <v-list-tile-sub-title v-if="item.titleLong">{{ item.titleLong }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon @click.prevent="item.selected = !item.selected" :color="item.selected ? 'accent' : ''">
                  {{ item.selected ? 'check_box' : 'check_box_outline_blank' }}
                </v-icon>
              </v-list-tile-action>                  
            </v-list-tile>
          </draggable>
        </v-list>          
        <v-subheader>Veredelung</v-subheader>
        <v-list dense subheader>
          <draggable
            v-model="formTitle.additional"
            handle=".drag-handle" group="title"
            style="min-height: 10px;">
            <v-list-tile v-for="item in formTitle.additional" :key="item.title">
              <v-list-tile-action class="drag-handle" @click.stop>
                <v-icon color="grey darken-3">menu</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.titleShort }}</v-list-tile-title>
                <v-list-tile-sub-title v-if="item.titleLong">{{ item.titleLong }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon @click.prevent="item.selected = !item.selected" :color="item.selected ? 'accent' : ''">
                  {{ item.selected ? 'check_box' : 'check_box_outline_blank' }}
                </v-icon>
              </v-list-tile-action>                  
            </v-list-tile>
          </draggable>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn @click.native="dialogTitle = false" small flat>Abbrechen</v-btn>
          <v-btn @click.native="formTitleSave" small flat color="primary">Übernehmen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogZutat"
      :transition="false"
      max-width="500"
      scrollable>       
      <v-card>
        <v-card-title>
          <v-layout>
            <h3>{{ formZutatIndex === null ? 'Neue Zutat' : 'Zutat bearbeiten' }}</h3>
            <v-spacer></v-spacer>
            <v-icon
              @click.prevent="formZutatCollapsed = !formZutatCollapsed">
              {{ formZutatCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
            </v-icon>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="pt-0">
          <v-text-field
            ref="formZutatTitleRef"
            v-model.trim="formZutat.zutat.title"
            @keyup.enter="formZutatSave"
            browser-autocomplete="off"
            hide-details single-line
            autofocus
            label="Titel"
            @click:clear="resetFormZutat"
            clearable></v-text-field>
          <v-textarea
            v-if="!formZutatCollapsed"
            v-model.trim="formZutat.zutat.details" 
            hide-details single-line rows="1" auto-grow
            label="Details"
            clearable></v-textarea>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0 px-0" style="height: 200px;">
          <v-list dense>
            <transition-group name="fade-transition" tag="div">
              <v-list-tile
                v-for="zutat in zutatenListFiltered" :key="zutat._id"
                @click.prevent="updateFormZutat(zutat._id)">
                <v-list-tile-content>
                  <v-list-tile-title>{{ zutat.title }}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="zutat.details">{{ zutat.details }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </transition-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn 
            v-if="formZutatIndex !== null"
            small flat @click.native="resetFormZutat(); formZutatSave()">
            <v-icon left>delete</v-icon>Löschen</v-btn>
          <v-btn 
            @click.native="formZutatSave"
            small flat color="primary">
            {{ formZutatIndex === null ? 'Hinzufügen' : 'Speichern' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogMeldung"
      :transition="false"
      max-width="500"
      scrollable>       
      <v-card>
        <v-form ref="formMeldungRef" @submit.prevent="formMeldungSend">
        <v-card-title class="subheading">
          Rezeptur melden
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="formMeldung">
          <v-layout v-for="(zutat, index) in formMeldung.zutaten" :key="index">            
            <v-flex xs8 class="pr-1">
              <v-text-field
                v-model.number="formMeldung.zutaten[index].menge"
                type="number"
                :rules="[val => !!val || val === 0 || 'Bitte angeben']"
                required
                :label="zutat.zutat.title"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-select
                v-model.number="formMeldung.zutaten[index].mengeEinheit"
                required
                :items="['g', 'ml']"
                :rules="[val => !!val || 'Bitte angeben']"
                label="Einheit"
                dense
                menu-props="offsetY, allowOverflow"></v-select>
            </v-flex>
          </v-layout>
          <v-textarea v-model="formMeldung.meldungText" auto-grow :rows="3"
            label="Nachricht"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn
            small flat @click="dialogMeldung = false">Abbrechen</v-btn>
          <v-btn 
            small flat color="primary" type="submit">
            <v-icon left>email</v-icon>Wappen</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmLeave" max-width="500">
      <v-card tile>
        <v-card-text class="subheading">Seite wirklich verlassen?</v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn
            @click.native="dialogConfirmLeave = false"
            small flat>Abbrechen</v-btn>
          <v-btn
            @click.capture="skipRouteLeaveChecks = true"
            :to="toRoute"
            small flat color="primary">Verlassen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-flex>
  </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import Sortable from 'sortablejs'

  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import store from '@/store'

  import * as moment from 'moment'
  moment.locale('de')

  const emptyEtikett = {
    title: null,
    fruchtanteil: null,
    hergestelltAus: null,
    freitext: null,
    qrCode: null,
    mhd: null,
    fuellMenge: null,
    fuellMengeUnit: null,
    count: null,
    zutaten: null,
    vorlage: {
      id: null,
      sizeId: null,
      // Big endian (most significant bit first)
      options: [/* undefined, false, true */]
    }
  }

  const emptyZutat = {
    anteil: null,
    zutat: {
      title: null,
      details: null,
      frucht: false,
      alkoholisch: false,
      allergen: false,
      common: false
    }
  }

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      produkt: null,
      etikett: JSON.parse(JSON.stringify(emptyEtikett)),
      parentJob: null,
      preview: false,
      dialogMhd: false,
      dialogZutat: false,
      dialogTitle: false,
      dialogMeldung: false,
      // Change selected zutat as copy in separate form
      // etikett.zutaten (zutatenModel) is computed and needs initialization before change
      formZutatIndex: null,
      formZutatCollapsed: true,
      formZutat: JSON.parse(JSON.stringify(emptyZutat)),
      formMeldung: null,
      // Used to store unique keys for zutaten data table table rows
      zutatenKeys: new WeakMap(),
      currentZutatKey: 0,
      // Async fetched zutaten list
      zutatenList: null,
      rezeptur: null,
      rezepturNWA: null,
      formTitle: {
        main: [],
        additional: []
      },
      // Navigation
      fromRoute: null,
      toRoute: null,
      skipRouteLeaveChecks: false,
      dialogConfirmLeave: false,
      expPanelOpened: null
    }),
    metaInfo: {
      title: 'Etikett drucken | Admin'
    },    
    computed: {
      defaultValues () {
        var defaultValues = {}
        if (this.vorlageModel && this.vorlageSizeModel) {
          defaultValues = {
            ...defaultValues,
            ...this.vorlageModel.defaultValues,
            ...this.vorlageSizeModel.defaultValues
          }
          if (this.vorlageModel.haltbarkeit) {
            defaultValues.mhd = moment().add(this.vorlageModel.haltbarkeit, 'months').format('YYYY-MM-DD')
          }
        }
        if (this.produkt) {
          defaultValues = {
            ...defaultValues,
            title: this.produkt.title,
            zutaten: this.produkt.zutaten
          }
          if (this.produkt.fruchtanteil > 0) {
            defaultValues.fruchtanteil = this.produkt.fruchtanteil
          }
          if (this.produkt.labelFreitext) {
            defaultValues.freitext = this.produkt.labelFreitext
          }
          // TODO: produkt primary sku fuellmenge
          // Related: vorlageSizeIdModel
          if (this.produkt.skus.length) {
            defaultValues.fuellMengeUnit = this.produkt.skus[0].preis.fuellMengeUnit
          }
          if (this.produkt.hergestelltAus) {
            defaultValues.hergestelltAus = this.produkt.hergestelltAus
          }
        }
        return defaultValues
      },
      produktChanged () {
        return this.produktZutatenChanged || this.produktFruchtanteilChanged
      },
      produktZutatenChanged () {
        if (this.produkt) {
          if (this.etikett.zutaten !== null) {
            const produktZutatenTitles = this.produkt.zutaten.map(zutat => zutat.zutat.title).sort()
            const etikettZutatenTitles = this.etikett.zutaten.map(zutat => zutat.zutat.title).sort()
            for (let i = 0; i < produktZutatenTitles.length; i++) {
              if (etikettZutatenTitles[i] !== produktZutatenTitles[i]) {
                return true
              }
            }
            return produktZutatenTitles.length !== etikettZutatenTitles.length
          }
        }
        return false
      },
      produktFruchtanteilChanged () {
        if (this.produkt) {
          if (this.etikett.fruchtanteil !== null) {
            if (this.etikett.fruchtanteil !== this.produkt.fruchtanteil) {
              return true;
            }
          }
        }
        return false        
      },      
      // Vorlage models
      vorlageIdModel: {
        get () {
          if (this.etikett.vorlage.id) {
            return this.etikett.vorlage.id
          } else if (this.vorlagenListItems.length) {
            // v-select: item-value="text"
            return this.vorlagenListItems[0].text
          } else {
            return null
          }
        },
        set (vorlageId) {
          this.etikett.vorlage.id = vorlageId
        }
      },
      vorlageModel () {
        return this.vorlagenListItems.find(vorlage => vorlage.text === this.vorlageIdModel)
      },
      vorlageSizeIdDefault () {
        const vorlageSizeMatches = this.vorlageModelSizeItems
          .filter(sizeItem => !this.vorlageSizeItemDisabled(sizeItem))
          .map(sizeItem => {
            const rulesApplied = { minFuellMenge: 0, maxFuellMenge: 0 }
            if (sizeItem.rules && this.etikett.fuellMenge !== null) {
              if ('minFuellMenge' in sizeItem.rules) {
                rulesApplied.minFuellMenge = this.etikett.fuellMenge >= sizeItem.rules.minFuellMenge ? 1 : -1
              }
              if ('maxFuellMenge' in sizeItem.rules) {
                rulesApplied.maxFuellMenge = this.etikett.fuellMenge <= sizeItem.rules.maxFuellMenge ? 1 : -1
              }
            }
            return { ...sizeItem, rulesApplied }
          })
          // vorlageModelSizeItems are in descending order of preference
          // compare function is reversed to keep natural ordering for equal a and b
          .sort(({ rulesApplied: a }, { rulesApplied: b }) => {
            const aNeg = Object.values(a).filter(val => val === -1).length
            const bNeg = Object.values(b).filter(val => val === -1).length
            if (aNeg === bNeg) {
              const aPos = Object.values(a).filter(val => val === 1).length
              const bPos = Object.values(b).filter(val => val === 1).length
              if (aPos === bPos) {     
                if (a.maxFuellMenge === b.maxFuellMenge) {
                  if (a.minFuellMenge === b.minFuellMenge) {
                    const aNeutral = Object.values(a).filter(val => val === 0).length
                    const bNeutral = Object.values(b).filter(val => val === 0).length
                    if (aNeutral === bNeutral) {
                      return 0
                    }
                    return aNeutral > bNeutral ? -1 : 1
                  }
                  return a.minFuellMenge > b.minFuellMenge ? -1 : 1
                }
                return a.maxFuellMenge > b.maxFuellMenge ? -1 : 1      
              }
              return aPos > bPos ? -1 : 1
            }
            return aNeg > bNeg ? 1 : -1
          })
        if (vorlageSizeMatches.length) {
          return vorlageSizeMatches[0].text
        } else if (this.vorlageModelSizeItems.length) {
          return this.vorlageModelSizeItems[0].text
        } else {
          return null
        }
      },
      vorlageSizeIdModel: {
        get () {
          return this.etikett.vorlage.sizeId !== null
            ? this.etikett.vorlage.sizeId
            : this.vorlageSizeIdDefault
        },
        set (vorlageSizeId) {
          this.etikett.vorlage.sizeId = vorlageSizeId
        }
      },
      vorlageSizeModel () {
        return this.vorlageModelSizeItems.find(size => size.text === this.vorlageSizeIdModel)
      },
      optionsMaskModel: {
        get () {
          return Object.values(this.vorlageOpts).reduce((mask, flag) => {
            // Defaults
            switch (flag) {
              case this.vorlageOpts.FLAG_LONG_TITLE: {
                if (this.titleModel.length > this.vorlageOptsLongTitleChars) {
                  mask |= flag
                }
                break;
              }
            }
            // Overwrites
            const optionsFlagPos = Math.log2(flag);
            const optionsFlagBool = this.etikett.vorlage.options[optionsFlagPos];
            if (optionsFlagBool !== undefined && optionsFlagBool !== null) {
              mask = optionsFlagBool
                ? mask | flag   // Set
                : mask & ~flag  // Clear
            }
            return mask
          }, 0b0)
        },
        set (mask) {
          Object.values(this.vorlageOpts).forEach(flag => {
            if ((this.optionsMaskModel ^ mask) & flag) {
              const optionsFlagPos = Math.log2(flag);
              this.$set(this.etikett.vorlage.options, optionsFlagPos, !!(mask & flag))
            }
          })
        }
      },
      // Etikett models
      etikettModel () {
        return {
          title: this.titleModel,
          fruchtanteil: this.fruchtanteilModel,
          hergestelltAus: this.hergestelltAusModel,
          freitext: this.freitextModel,
          qrCode: this.qrCodeModel,
          qrCodeLink: this.qrCodeModel ? this.qrCodeItems.find(item => item.text === this.qrCodeModel).link : null,
          qrCodeLinkText: this.qrCodeModel ? this.qrCodeItems.find(item => item.text === this.qrCodeModel).linkText : null,
          mhd: this.mhdModel,
          fuellMenge: this.fuellMengeModel,
          fuellMengeUnit: this.fuellMengeUnitModel,
          count: this.countModel,
          zutaten: this.zutatenModel,
          vorlage: {
            id: this.vorlageIdModel,
            sizeId: this.vorlageSizeIdModel,
            optionsMask: this.optionsMaskModel
          }          
        }
      },
      titleModel: {
        get () {
          return this.etikett.title !== null ? this.etikett.title : this.defaultValues.title
        },
        set (title) {
          this.etikett.title = title
        }
      },
      fruchtanteilModel: {
        get () {
          return this.etikett.fruchtanteil !== null ? this.etikett.fruchtanteil : this.defaultValues.fruchtanteil
        },
        set (fruchtanteil) {
          this.etikett.fruchtanteil = fruchtanteil
        }
      },
      hergestelltAusModel: {
        get () {
          return this.etikett.hergestelltAus !== null ? this.etikett.hergestelltAus : this.defaultValues.hergestelltAus
        },
        set (hergestelltAus) {
          this.etikett.hergestelltAus = hergestelltAus
        }
      },
      freitextModel: {
        get () {
          return this.etikett.freitext !== null ? this.etikett.freitext : this.defaultValues.freitext
        },
        set (freitext) {
          this.etikett.freitext = freitext
        }
      },
      qrCodeModel: {
        get () {
          if (this.produkt && !this.produktChanged) {
            return 'Produktseite'
          } else {
            return 'Startseite'
          }
        },
        set (qrCode) {
          this.etikett.qrCode = qrCode
        }
      },
      mhdModel: {
        get () {
          return this.etikett.mhd !== null ? this.etikett.mhd : this.defaultValues.mhd
        },
        set (mhd) {
          this.etikett.mhd = mhd
        }
      },
      mhdModelFormatted () {
        return moment(this.mhdModel, 'YYYY-MM-DD').format('DD.MM.YYYY')
      },
      fuellMengeModel: {
        get () {
          return this.etikett.fuellMenge !== null ? this.etikett.fuellMenge : this.defaultValues.fuellMenge
        },
        set (fuellMenge) {
          this.etikett.fuellMenge = fuellMenge
        }
      },
      fuellMengeUnitModel: {
        get () {
          return this.etikett.fuellMengeUnit !== null ? this.etikett.fuellMengeUnit : this.defaultValues.fuellMengeUnit
        },
        set (fuellMengeUnit) {
          this.etikett.fuellMengeUnit = fuellMengeUnit
        }
      },
      countModel: {
        get () {
          return this.etikett.count !== null ? this.etikett.count : this.defaultValues.count
        },
        set (count) {
          this.etikett.count = count
        }
      },
      zutatenModel: {
        get () {
          return this.etikett.zutaten !== null
            ? this.etikett.zutaten
            : (this.defaultValues.zutaten
              ? this.defaultValues.zutaten
              : [])
        },
        // Do not push or splice
        set (zutaten) {
          this.etikett.zutaten = zutaten
        }
      },
      // Select items
      vorlagenListItems () {
        if (!this.produkt) {
          return this.vorlagenList
        } else {
          return this.vorlagenList.filter(vorlage => {
            if (vorlage.produkttypen.includes(this.produkt.produkttyp)) {
              return !vorlage.categories || vorlage.categories.some(alias => {
                return this.produkt.categories?.map(c => c.alias).includes(alias)
              })
            }
          }).sort((v1, v2) => {
            const v1ProdukttypIndex = v1.produkttypen.indexOf(this.produkt.produkttyp)
            const v2ProdukttypIndex = v2.produkttypen.indexOf(this.produkt.produkttyp)
            const val = v1ProdukttypIndex - v2ProdukttypIndex
            if (val === 0) {
              if (v1.categories && !v2.categories) return -1
              else if (!v1.categories && v2.categories) return 1
            }
            return val
          })
        }
      },
      vorlageModelSizeItems () {
        if (this.vorlageModel) {
          return this.vorlageModel.sizes
        } else {
          return []
        }
      },
      qrCodeItems () {
        return [
          {
            text: 'Produktseite',
            linkText: 'QRCode: Produktseite',
            link: `https://marme.info/p/${this.produkt ? this.produkt.lfdNr : null}`,
            disabled: !this.produkt || this.produktChanged
          },
          {
            text: 'Startseite',
            linkText: '',
            link: 'https://marme.info'
          },
          {
            text: 'Facebook',
            linkText: 'QRCode: Facebook',
            link: 'http://fb.me/marmelaedchen'

          }
        ]
      },
      longTitleHint () {
        if (this.vorlageSizeModel) {
          if (this.vorlageSizeModel.supportedOptionsMask & this.vorlageOpts.FLAG_LONG_TITLE) {
            return this.optionsMaskModel & this.vorlageOpts.FLAG_LONG_TITLE
              ? 'Schrift kleiner'
              : 'Schrift normal'
          }
        }
      },      
      // Auto title
      autoTitleModel () {
        const main = [...new Set(this.formTitle.main.filter(zutat => zutat.selected).map(zutat => zutat.titleShort))]
        const additional = [...new Set(this.formTitle.additional.filter(zutat => zutat.selected).map(zutat => zutat.titleShort))]
        var titleMain = [...main, 'Fruchtaufstrich'].join('-')
        if (additional.length === 1) {
          return `${titleMain} mit ${additional[0]}`
        } else if (additional.length === 2) {
          return `${titleMain} mit ${additional.join(' und ')}`
        } else if (additional.length >= 3) {
          return `${titleMain} mit ${additional.slice(0, -1).join(', ')} und ${additional[additional.length - 1]}`
        } else {
          return titleMain
        }
      },
      // FormZutat
      zutatenListFiltered () {
        if (this.zutatenList) {
          return this.zutatenList.filter(zutat => {
            const search = this.formZutat.zutat.title || ''
            return zutat.title.toLowerCase().startsWith(search.trim().toLowerCase())
          })
        } else {
          return []
        }
      },
      naehrwertangaben () {
        if (this.produkt) {
          return this.produkt.naehrwertangaben
        }
      },
      expPanelOpenedModel: {
        get () {
          if (this.expPanelOpened === null && this.vorlageSizeModel) {
            return !!this.vorlageSizeModel.lmiv
          }
          return this.expPanelOpened
        },
        set (opened) {
          this.expPanelOpened = opened
        }
      },
      naehrwertangabenEnergieHTML () {
        const kj = this.naehrwertangaben.brennwertKJ.formatted
        const kcal = [this.naehrwertangaben.brennwertKJ.formattedKcal]
        if (this.naehrwertangaben.methode === 'rezeptur+zutaten') {
          if (this.rezepturNWA && this.rezepturNWA.zutaten.data && this.rezepturNWA.rezeptur.data) {
            kcal.unshift(`<span class="text--secondary">${this.rezepturNWA.zutaten.data.brennwertKJ.formattedKcal}</span>`)
            kcal.push(`<span class="text--secondary">${this.rezepturNWA.rezeptur.data.brennwertKJ.formattedKcal}</span>`)
          }
        }
        return `<span>${kj} kJ / [${kcal.join(' ')}] kcal</span>`;
      },
      naehrwertangabenCarbsHTML () {
        const carbs = [this.naehrwertangaben.carbs.formatted]
        if (this.naehrwertangaben.methode === 'rezeptur+zutaten') {
          if (this.rezepturNWA && this.rezepturNWA.zutaten.data && this.rezepturNWA.rezeptur.data) {
            carbs.unshift(`<span class="text--secondary">${this.rezepturNWA.zutaten.data.carbs.formatted}</span>`)
            carbs.push(`<span class="text--secondary">${this.rezepturNWA.rezeptur.data.carbs.formatted}</span>`)
          }
        }
        return `<span>[${carbs.join(' ')}] g</span>`;
      },
      naehrwertangabenCarbsZuckerHTML () {
        const zucker = [this.naehrwertangaben.carbsZucker.formatted]
        if (this.naehrwertangaben.methode === 'rezeptur+zutaten') {
          if (this.rezepturNWA && this.rezepturNWA.zutaten.data && this.rezepturNWA.rezeptur.data) {
            zucker.unshift(`<span class="text--secondary">${this.rezepturNWA.zutaten.data.carbsZucker.formatted}</span>`)
            zucker.push(`<span class="text--secondary">${this.rezepturNWA.rezeptur.data.carbsZucker.formatted}</span>`)
          }
        }
        return `<span>[${zucker.join(' ')}] g</span>`;
      },
      ...mapGetters('admin/labels', ['vorlagenList']),
      ...mapGetters('products', ['produkttypFriendly']),
      ...mapState({
        // arrow functions can make the code very succinct!
        vorlageOpts: state => state.admin.labels.vorlageOpts,
        vorlageOptsBits: state => state.admin.labels.vorlageOptsBits,
        vorlageOptsLongTitleChars: state => state.admin.labels.vorlageOptsLongTitleChars
      })
    },
    watch: {
      /*
      'etikett.fuellMenge': function (val) {
        if (this.vorlageSizeModel) {
          if (this.vorlageSizeModel.rules &&
            ((this.vorlageSizeModel.rules.minFuellMenge &&
            val < this.vorlageSizeModel.rules.minFuellMenge) ||
            (this.vorlageSizeModel.rules.maxFuellMenge &&
            val > this.vorlageSizeModel.rules.maxFuellMenge))) {
            const vorlageSizeMatches = this.vorlageModelSizeItems.filter(size => {
              return !size.rules ||
                ((!size.rules.maxFuellMenge || val <= size.rules.maxFuellMenge) &&
                (!size.rules.minFuellMenge || val >= size.rules.minFuellMenge))
            }).filter(sizeItem => {
              return !this.vorlageSizeItemDisabled(sizeItem)
            })
            if (vorlageSizeMatches.length) {
              this.etikett.vorlage.sizeId = vorlageSizeMatches[0].text
            }
          }
        }
      },
      'produktZutatenChanged': function (val) {
        if (this.vorlageSizeModel) {
          if (this.vorlageSizeModel.lmiv && val && this.etikett.vorlage.sizeId) {
            const vorlageSizeMatches = this.vorlageModelSizeItems.filter(sizeItem => {
              return !this.vorlageSizeItemDisabled(sizeItem)
            })
            if (vorlageSizeMatches.length) {
              this.etikett.vorlage.sizeId = vorlageSizeMatches[0].text
            }            
          }
        }
      }
      */
    },
    async beforeRouteEnter ({ params: { slug, jobId } }, from, next) {
      // Set default values for /etiketten/drucken
      var produkt = null
      var etikett = JSON.parse(JSON.stringify(emptyEtikett))
      var parentJob = null

      try {
        if (jobId) {
          const job = store.getters['admin/labels/findJob'](parseInt(jobId))
          if (job) {
            if (job.data.produkt) {
              // produkt = job.data.produkt
              // Reload produkt because job.data.produkt could be outdated 
              slug = job.data.produkt.slug
            }
            etikett = JSON.parse(JSON.stringify(job.data.etikett))
            // Create snapshot
            parentJob = JSON.parse(JSON.stringify({
              ...job,
              data: null
            }))
          } else {
            throw new Error(`Job ${jobId} not found`)
          }
        }
        if (slug) {
          produkt = await store.dispatch('products/findProdukt', { slug, forceReload: true })
        }
      } catch (err) {
        return next('/404')
      }
      next(vm => {
        vm.produkt = produkt
        vm.etikett = etikett
        vm.parentJob = parentJob
        vm.fromRoute = from
        vm.dialogConfirmLeave = false
      })
    },
    async beforeRouteUpdate ({ params: { slug, jobId } }, from, next) {
      // Set default values for /etiketten/drucken
      var produkt = null
      var etikett = JSON.parse(JSON.stringify(emptyEtikett))
      var parentJob = null      

      try {
        if (jobId) {
          const job = store.getters['admin/labels/findJob'](parseInt(jobId))
          if (job) {
            if (job.data.produkt) {
              // produkt = job.data.produkt
              // Reload produkt because job.data.produkt could be outdated 
              slug = job.data.produkt.slug
            }
            etikett = JSON.parse(JSON.stringify(job.data.etikett))
            // Create snapshot
            parentJob = JSON.parse(JSON.stringify({
              ...job,
              data: null
            }))
          } else {
            throw new Error(`Job ${jobId} not found`)
          }
        }
        if (slug) {
          produkt = await store.dispatch('products/findProdukt', { slug, forceReload: true })
        }
      } catch (err) {
        return next('/404')
      }
      this.produkt = produkt
      this.etikett = etikett
      this.parentJob = parentJob      
      this.fromRoute = from
      this.dialogConfirmLeave = false
      this.rezeptur = null,
      this.rezepturNWA = null,

      next()
    },
    beforeRouteLeave (to, from, next) {
      // Skip route leave checks for print/abort
      if (this.skipRouteLeaveChecks) {
        this.skipRouteLeaveChecks = false
        return next()
      }

      // Close open dialogs and stop (back) navigation
      const dialogZutatAnteilRefs = Object.keys(this.$refs)
        .filter(refName => refName.includes('dialogZutatAnteilRef'))
        .map(dialogRefName => this.$refs[dialogRefName])
        .filter(ref => ref !== undefined)
      if (this.dialogTitle || this.dialogZutat || this.dialogMhd || this.dialogMeldung || this.dialogConfirmLeave || dialogZutatAnteilRefs.find(ref => ref.isActive)) {
        this.dialogTitle = false
        this.dialogZutat = false
        this.dialogMhd = false
        this.dialogMeldung = false
        this.dialogConfirmLeave = false
        dialogZutatAnteilRefs.forEach(ref => { ref.cancel() })
        return next(false)
      }

      // Close select inputs if liekly back navigation
      const likelyBackNavigation = !this.fromRoute || this.fromRoute.path === '/' || (this.fromRoute.fullPath === to.fullPath)
      const selectRefs = [this.$refs.formVorlageRef, this.$refs.formVorlageSizeRef, this.$refs.formQrCodeRef].filter(ref => ref !== undefined)
      // TODO: Check focus
      // TF: formTitleRef, formFreitextRef, formFuellMengeRef, formCountRef
      // Conditional TF: formHergestelltAusRef, formFruchtanteilRef
      if (likelyBackNavigation && selectRefs.find(ref => ref.isMenuActive)) {
        selectRefs.forEach(ref => { ref.blur() })
        return next(false)
      }

      // Confirm leave is user made input
      const userMadeInput = Object.values(this.etikett).some(val => val !== null)
      if (userMadeInput) {
        // Save destination route for confirm dialog
        this.toRoute = to
        this.dialogConfirmLeave = true
        return next(false)
      }

      next()
    },
    created () {
      // Load zutaten asynchronously
      this.getZutaten().then(zutaten => {
        this.zutatenList = zutaten
      })
      this.$nextTick(() => {
        if (this.produkt && this.produkt.rezeptur) {
          this.findRezeptur(this.produkt.rezeptur).then(data => {
            this.rezeptur = data.rezeptur
            this.rezepturNWA = data.naehrwertangaben  // Full
          }).catch(console.log)
        }
      })
    },
    mounted () {
      const table = this.$refs.dTable.$el.getElementsByTagName('tbody')[0]
      Sortable.create(table, {
        onEnd: ({ newIndex, oldIndex }) => {
          const zutatenChanged = this.zutatenModel.slice(0)
          const item = zutatenChanged.splice(oldIndex, 1)[0]
          zutatenChanged.splice(newIndex, 0, item)
          this.zutatenModel = zutatenChanged
        },
        handle: '.handle',
        scroll: false
      })
    },
    methods: {
      vorlageSizeItemDisabled (sizeItem) {
        if (sizeItem.lmiv) {
          return !this.produkt || this.produktZutatenChanged // || this.produktFruchtanteilChanged // || !this.naehrwertangaben
        }
        return false
      },
      preventNewLine (event) {
        if (event.which == '13') {
          event.preventDefault();
        }
      },
      formEtikettSubmit (print = true) {
        const fn = print ? this.printEtikett : this.storeEtikett
        // Lock in values
        if (!print) {
          this.etikett.mhd = this.mhdModel
          this.etikett.fuellMenge = this.fuellMengeModel
          this.etikett.fuellMengeUnit = this.fuellMengeUnitModel
          this.etikett.count = this.countModel
          this.etikett.vorlage.sizeId = this.vorlageSizeIdModel          
        }

        fn({
          produkt: this.produkt,
          produktChanged: this.produktChanged,
          vorlage: JSON.parse(JSON.stringify(this.vorlageModel)),
          etikett: JSON.parse(JSON.stringify(this.etikett)),
          etikettValues: JSON.parse(JSON.stringify(this.etikettModel)),
          etikettCSV: {
            TITEL: this.etikettModel.title ? this.etikettModel.title : '',
            FRUCHTANTEIL: this.etikettModel.fruchtanteil ? this.etikettModel.fruchtanteil : '',
            HERGESTELLT_AUS: this.etikettModel.hergestelltAus ? this.etikettModel.hergestelltAus : '',
            ZUTATEN: this.formatZutaten(this.etikettModel.zutaten, { unicodeAllergen: true }),
            FREITEXT: this.etikettModel.freitext ? this.etikettModel.freitext : '',
            QRCODE: this.etikettModel.qrCodeLink
              ? this.etikettModel.qrCodeLink
              : 'https://marme.info',
            ADDRESS_LINE_4: this.etikettModel.qrCodeLinkText ? this.etikettModel.qrCodeLinkText : '',
            MHD: this.etikettModel.mhd ? this.mhdModelFormatted : '',
            FUELLMENGE: this.etikettModel.fuellMenge
              ? `${this.etikettModel.fuellMenge} ${this.etikettModel.fuellMengeUnit ? this.etikettModel.fuellMengeUnit : 'g'}`.trim()
              : '',
            NW_REFERENZMENGE: this.naehrwertangaben ? `${this.naehrwertangaben.referenzWert} ${this.naehrwertangaben.referenzEinheit}` : '100 g',
            NW_FETT: this.naehrwertangaben ? this.naehrwertangaben.fett.formatted : '',
            NW_CARBS: this.naehrwertangaben ? this.naehrwertangaben.carbs.formatted : '',
            NW_EIWEISS: this.naehrwertangaben ? this.naehrwertangaben.eiweiss.formatted : '',
            NW_SALZ: this.naehrwertangaben ? this.naehrwertangaben.salz.formatted : '',
            NW_FETT_SATURIERT: this.naehrwertangaben ? this.naehrwertangaben.fettSaturiert.formatted : '',
            NW_CARBS_ZUCKER: this.naehrwertangaben ? this.naehrwertangaben.carbsZucker.formatted : '',
            NW_BRENNWERT_KJ: this.naehrwertangaben ? this.naehrwertangaben.brennwertKJ.formatted : '',
            NW_BRENNWERT_KCAL: this.naehrwertangaben ? this.naehrwertangaben.brennwertKJ.formattedKcal : '',
          },
          parentJob: JSON.parse(JSON.stringify(this.parentJob)),
          printOptions: {
            template: this.vorlageSizeModel.label + '_' +
              Number(this.vorlageSizeModel.supportedOptionsMask & this.optionsMaskModel)
                .toString(2)
                .padStart(this.vorlageOptsBits || 4, '0') +
              '.glabels',
            printer: this.vorlageSizeModel.printer,
            count: this.countModel
          }
        })
        this.skipRouteLeaveChecks = true
        this.$router.push('/etiketten')
      },
      dialogTitleShow () {
        this.formTitle = this.zutatenModel.reduce((formTitle, zutat) => {
          if (!zutat.zutat.common) {
            const titleShort = zutat.zutat.titleShort || zutat.zutat.title
            const formTitleZutat = { 
              title: zutat.zutat.title,
              titleShort: titleShort.charAt(0).toUpperCase() + titleShort.slice(1),
              titleLong: zutat.zutat.titleShort ? zutat.zutat.title : null,
              selected: true
            }
            if (zutat.anteil > 10 || zutat.zutat.frucht) {
              formTitle.main.push(formTitleZutat)
            } else {
              formTitle.additional.push(formTitleZutat)
            }
          }
          return formTitle
        }, { main: [], additional: [] })
        this.dialogTitle = true
      },
      formTitleSave () {
        this.titleModel = this.autoTitleModel
        this.dialogTitle = false
      },
      // Open Zutat title/details dialog
      dialogZutatShow (index = null) {
        this.setFormZutatIndex(index)
        this.dialogZutat = true
        this.$nextTick(() => {
          if (this.$refs.formZutatTitleRef) {
            this.$refs.formZutatTitleRef.focus()
          }
        })
      },
      dialogMeldungShow () {
        const gelierzuckerZutatId = '5b70c21bbb11c46a36e40603'
        this.formMeldung = JSON.parse(JSON.stringify({
          meldungDate: new Date(),
          meldungText: 'Hallo Kind!\n...\n\nMama',
          produkt: {
            _id: this.produkt._id,
            title: this.produkt.title,
            slug: this.produkt.slug,
            rezeptur: this.produkt.rezeptur
          },
          zutaten: [...this.zutatenModel.map(zutatWrapper => ({
            zutat: {
              _id: zutatWrapper.zutat._id,
              title: zutatWrapper.zutat.title
            },
            anteil: zutatWrapper.anteil,
            menge: zutatWrapper.zutat._id === gelierzuckerZutatId ? 1000 : null,
            mengeEinheit: zutatWrapper.zutat._id === gelierzuckerZutatId ? 'g' : null,
          })), {
            zutat: {
              title: 'Krambambuli'
            },
            anteil: null,
            menge: null,
            mengeEinheit: 'ml'
          }]
        }))
        this.dialogMeldung = true
      },
      formMeldungSend () {
        if (this.$refs.formMeldungRef.validate()) {
          this.sendMeldung(JSON.parse(JSON.stringify(this.formMeldung)))
            .catch(err => console.log(err))
          this.dialogMeldung = false
        }
      },
      // Copy values from zutatenModel to formZutat and set index
      setFormZutatIndex (index) {
        this.formZutatIndex = index
        if (index === null || !this.zutatenModel[index]) {
          this.formZutat = JSON.parse(JSON.stringify(emptyZutat))
          this.formZutatCollapsed = true
        } else {
          this.formZutat = JSON.parse(JSON.stringify(this.zutatenModel[index]))
          this.formZutatCollapsed = !this.formZutat.zutat.details
        }
      },
      // Allow previous edit-dialog to close and save
      setFormZutatIndexDelayed (index) {
        setTimeout(() => { this.setFormZutatIndex(index) }, 50)
      },
      // Upon zutaten list view selection
      updateFormZutat (zutatId) {
        const zutat = this.zutatenList.find(zutat => zutat._id === zutatId)
        this.formZutat.zutat = JSON.parse(JSON.stringify(zutat))
        this.formZutatCollapsed = !this.formZutat.zutat.details
        // Keep focus
        this.$refs.formZutatTitleRef.focus()
      },
      // Upon title text field clear
      resetFormZutat () {
        this.formZutat.zutat.title = null
        this.formZutat.zutat.details = null
        this.formZutatCollapsed = true
      },
      // Update zutatenModel
      formZutatSave () {
        const zutatenChanged = this.zutatenModel.slice(0)
        if (this.formZutatIndex !== null && this.zutatenModel[this.formZutatIndex]) {
          if (this.formZutat.zutat.title) { // Update
            zutatenChanged[this.formZutatIndex] = this.formZutat
          } else { // Remove
            zutatenChanged.splice(this.formZutatIndex, 1)
          }
        } else if (this.formZutat.zutat.title) { // Insert
          const zutatenFruchtIndex = this.zutatenModel.map((item, index) => ({ index, frucht: item.zutat.frucht })).filter(item => item.frucht)
          // If new zutat is of type frucht insert after last frucht zutat
          if (this.formZutat.zutat.frucht && zutatenFruchtIndex.length) {
            const pos = zutatenFruchtIndex[zutatenFruchtIndex.length - 1].index + 1
            zutatenChanged.splice(pos, 0, this.formZutat)
          } else {
            zutatenChanged.push(this.formZutat)
          }
        }
        this.zutatenModel = zutatenChanged
        this.dialogZutat = false
      },
      zutatKey (zutat) {
        if (!this.zutatenKeys.has(zutat)) {
          this.zutatenKeys.set(zutat, ++this.currentZutatKey)
        }
        return this.zutatenKeys.get(zutat)
      },
      resetEtikett () {
        this.etikett = JSON.parse(JSON.stringify(emptyEtikett))
      },
      ...mapActions('admin/labels', ['storeEtikett', 'printEtikett']),
      ...mapActions('admin/products', ['getZutaten']),
      ...mapActions('admin/rezeptur', ['findRezeptur', 'sendMeldung'])
    },
    components: {
      draggable
    }    
  }
</script>

<style>
  #etiketten-druck-naehrwertangaben .v-list__tile {
    height: auto !important;
  }

  #etiketten-druck-exp-panel .v-expansion-panel__header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
</style>