<template>
  <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-card-text class="pb-0">
      <v-text-field
        v-model="search"
        clearable
        label="Suche"></v-text-field>
      <v-select
        v-model="status"
        :items="['gramm', 'ml', 'mit Zutat', 'ohne Zutat', 'neutral', 'nicht verwendet']"
        multiple chips small-chips dense
        :append-outer-icon="`fa fa-${sort}`"
        @click:append-outer="sort = sortItems[(sortItems.indexOf(sort) + 1) % sortItems.length]"
        clearable
        label="Status">
      </v-select>
    </v-card-text>
    <v-layout align-baseline>
      <v-subheader>Lebensmittel</v-subheader>
      <v-spacer></v-spacer>
      <v-btn flat small to="/naehrwerte/lebensmittel/anlegen">Anlegen</v-btn>
    </v-layout>      
    <v-list dense subheader class="mb-3" two-line>
      <template v-for="(lebensmittel, index) in filteredLebensmittel">
        <v-list-tile :key="lebensmittel._id" :to="`/naehrwerte/lebensmittel/${lebensmittel._id}`">
          <v-list-tile-content>
            <v-list-tile-title>{{ lebensmittel.title }}</v-list-tile-title>
            <v-list-tile-sub-title v-if="lebensmittel.rezepturen && lebensmittel.rezepturen.length">
              verwendet in {{ lebensmittel.rezepturen.length }} Rezepturen
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text>{{ lebensmittel.brennwertKJ.value.$numberDecimal }} kJ</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider v-if="index !== filteredLebensmittel.length -1"
          :key="`${lebensmittel._id}-divider`"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'

  export default {
    data: () => ({
      lebensmittel: [],
      status: null,
      search: null,
      sortItems: ['sort-alpha-down', 'sort-alpha-up', 'sort-numeric-down', 'sort-numeric-up'],
      sort: 'sort-alpha-down'
    }),
    metaInfo: {
      title: 'Alle Lebensmittel',
    },
    computed: {
      filteredLebensmittel () {
        var filteredLebensmittel = this.lebensmittel
          .filter(lebensmittel => {
            if (this.status && this.status.length) {
              return this.status.map(status => {
                switch (status) {
                  case 'gramm': return lebensmittel.referenzEinheit === 'g'
                  case 'ml': return lebensmittel.referenzEinheit === 'ml'
                  case 'mit Zutat': return !!lebensmittel.parent
                  case 'ohne Zutat': return !lebensmittel.parent
                  case 'neutral': return !!lebensmittel.neutral
                  case 'nicht verwendet': return !lebensmittel.rezepturen || lebensmittel.rezepturen.length === 0                  
                  default: return false
                }
              }).every(status => status === true)
            }
            return true
          })
          .filter(lebensmittel => {
            if (this.search) {
              return (lebensmittel.title || '').toLowerCase().includes(this.search.toLowerCase())
            }
            return true
          })

        if (this.sort.startsWith('sort-numeric')) {
          filteredLebensmittel = filteredLebensmittel.sort((lmA, lmB) => {
            return new Date(lmA.updatedAt).valueOf() - new Date(lmB.updatedAt).valueOf()
          })
        }
        if (this.sort === 'sort-alpha-up' || this.sort === 'sort-numeric-up') {
          filteredLebensmittel = filteredLebensmittel.reverse()
        }

        return filteredLebensmittel
      }
    },
    async beforeRouteEnter (to, from, next) {
      try {
        const lebensmittel = await store.dispatch('admin/rezeptur/getLebensmittel');
        next(vm => {
          vm.lebensmittel = lebensmittel
        })
      } catch (err) {
        next(false)
      }
    }
  }
</script>