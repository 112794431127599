<template>
  <v-container fill-height>
    <v-layout justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="fill-height">
          <v-layout column fill-height>
            <v-toolbar dense tabs card>
              <v-toolbar-title>
                Nährwerte
              </v-toolbar-title>
              <v-tabs slot="extension" color="grey lighten-4" fixed-tabs>
                <v-tab to="/naehrwerte/rezeptur">Rezeptur</v-tab>
                <v-tab to="/naehrwerte/lebensmittel">Lebensmittel</v-tab>
              </v-tabs>
            </v-toolbar>
            <router-view></router-view>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Übersicht',
      titleTemplate: 'Nährwerte - %s | Admin'      
    }
  }
</script>