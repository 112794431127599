<template>
  <v-container fill-height>
    <v-layout fill-height>
      <v-card class="flex">
        <v-card-title primary-title>
          <h3 class="title">Kategorien</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-list expand v-if="treeView" dense two-line>
            <ml-category-tree :value="treeView"></ml-category-tree>
          </v-list>
          <v-btn v-else @click="createRoot">Wurzel hinzufügen</v-btn>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import CategoryTree from './AdminCategoryTree.vue'
  import store from '@/store'

  export default {
    metaInfo: {
      title: 'Kategorien | Admin'
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('admin/categories/loadCategories').then(() => {
        next()
      }).catch(() => {
        next(false)
      })
    },
    computed: {
      ...mapGetters('admin/categories', ['treeView'])
    },
    methods: {
      ...mapActions('admin/categories', ['addCategory']),
      createRoot () {
        this.addCategory({
          title: 'Produkte',
          ancestors: [],
          alias: 'produkte',
          parent: null
        })
      }
    },
    components: {
      'ml-category-tree': CategoryTree
    }
  }
</script>
