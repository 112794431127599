<template>
  <v-container fill-height>
    <v-layout justify-center fill-height>
    <v-flex xs12 sm8 md6>
    <v-card class="fill-height">
      <v-layout column fill-height>
        <v-toolbar tabs card>          
          <v-menu offset-y>
            <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
            <v-list dense>
              <v-list-tile to="/katalog/anlegen" exact>
                <v-list-tile-title>Neues Produkt</v-list-tile-title>
              </v-list-tile>
              <v-list-tile :disabled="!form._id" :to="`/katalog/anlegen?vorlage=${form._id}`" exact>
                <v-list-tile-title>Kopie erstellen</v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile :disabled="!form._id" @click="dialogProduktDelete = true">
                <v-list-tile-title>Löschen</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-toolbar-title>
            <span v-if="!form._id">Produkt anlegen</span>
            <!-- FIXME: Does not update when :slug route param changes. Edge case -->
            <span v-else>Produktdetails</span>
          </v-toolbar-title>
          <v-tabs slot="extension" v-model="activeTab" light color="grey lighten-4" centered grow>
            <v-tab><v-icon>create</v-icon></v-tab>
            <v-tab><v-icon>euro_symbol</v-icon></v-tab>            
            <v-tab><v-icon>photo_camera</v-icon></v-tab>
            <v-tab><v-icon>toc</v-icon></v-tab>
            <v-tab><v-icon>build</v-icon></v-tab>            
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item>
            <v-card-text>
              <div class="subheading font-weight-bold">Allgemeine Informationen</div>
              <v-text-field v-model.trim="form.title" label="Bezeichnung" single-line></v-text-field>
              <v-textarea v-model.trim="form.beschreibung"
                rows="3" auto-grow 
                label="Beschreibung"
                placeholder="Wunderbaaar..."></v-textarea>
              <v-checkbox v-model="form.empfehlung"
                label="Auf Startseite anzeigen" class="mt-0"></v-checkbox>
              <v-textarea v-model.trim="form.empfehlungText" :disabled="!form.empfehlung"
                rows="2" auto-grow 
                label="Empfehlungstext"
                hint="Zum Bearbeiten Empfehlung aktivieren" persistent-hint
                placeholder="Zeit füüür..."></v-textarea>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text class="pb-0">
              <div class="subheading font-weight-bold">Varianten</div>
            </v-card-text>
            <v-list dense two-line>
              <v-layout>
                <v-subheader>Preis</v-subheader>
                <v-spacer></v-spacer>
                <v-btn flat small @click.stop="dialogPreisShow">Anlegen</v-btn>              
              </v-layout>
              <v-card-text class="py-0">
                <v-select
                  v-model="preisSelection"
                  :items="preiseList"
                  :item-text="item => formatFuellmengePreis(item)"
                  item-value="_id"
                  label="Preis auswählen"
                  return-object
                  single-line
                  hide-details
                  box
                  dense
                  menu-props="offsetY, allowOverflow">
                  <template slot="item" slot-scope="{ item, tile }">
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ formatFuellmengePreis(item) }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>                
                </v-select>
              </v-card-text>
              <v-layout>
                <v-subheader>SKU-Partials</v-subheader>
                <v-spacer></v-spacer>
                <v-btn flat small @click.stop="dialogSKUShow">Anlegen</v-btn>              
              </v-layout>
              <v-card-text class="py-0">
                <v-select
                  v-model="skuPartialsSelection"
                  :items="skuPartialsList"
                  :item-text="formatSkuPartial"
                  item-value="_id"
                  label="SKU definieren"
                  return-object
                  multiple
                  chips
                  deletable-chips
                  single-line
                  box
                  dense
                  menu-props="offsetY, allowOverflow">
                </v-select>
                <v-btn small :disabled="!preisSelection" @click="addSku">Hinzufügen</v-btn>
              </v-card-text>              
              <v-list-tile v-for="(item, index) in form.skus" :key="index">
                <v-list-tile-action @click.stop>
                  <v-menu offset-y>
                    <v-icon slot="activator">menu</v-icon>
                    <v-list dense>
                      <v-list-tile @click.prevent="form.skus.splice(index, 1)">
                        <v-list-tile-title>Löschen</v-list-tile-title>
                      </v-list-tile>
                      <v-divider></v-divider>
                      <v-list-tile :disabled="index === 0" @click.prevent="swapSku(index, index-1)">
                        <v-list-tile-title>Nach oben</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile :disabled="index === form.skus.length - 1" @click.prevent="swapSku(index, index+1)">
                        <v-list-tile-title>Nach unten</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.preis.title }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{ formatFuellmengePreis(item.preis) }}                    
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title v-if="item.partials && item.partials.length">
                    {{ item.partials.map(formatSkuPartial).join(', ') }}
                  </v-list-tile-sub-title>                  
                </v-list-tile-content>
                <v-list-tile-action >
                  <v-switch :true-value="1" :false-value="0" v-model="item.inventory"></v-switch>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-card-text class="pb-0">
              <div class="subheading font-weight-bold">Bilder</div>
              <v-img class="mt-3"
                v-if="bilderActiveIndex !== null"
                :src="form.images[bilderActiveIndex].urls.small"
                :height="croppieBoundary.height" contain>
              </v-img>
              <v-textarea 
                v-if="bilderActiveIndex !== null"
                rows="1" auto-grow label="Beschreibung"
                v-model.trim="form.images[bilderActiveIndex].caption"></v-textarea>
              <v-select
                v-if="bilderActiveIndex !== null"
                v-model="form.images[bilderActiveIndex].sku"
                :items="form.skus"
                item-value="_id"
                :item-text="(item) => item.preis.title + (item.partials.length ? ` (${item.partials.map(formatSkuPartial).join(', ')})` : '')"
                :item-disabled="(item) => !item._id"
                label="SKU auswählen"
                clearable
                return-object
                dense
                menu-props="offsetY, allowOverflow">
                <template slot="item" slot-scope="{ item, tile }">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ item.preis.title }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="item.partials && item.partials.length">
                      {{ item.partials.map(formatSkuPartial).join(', ') }}
                    </v-list-tile-sub-title>                  
                  </v-list-tile-content>
                </template>                
              </v-select>                
              <div class="text-xs-center">
                <v-pagination
                  ref="bilderPaginationRef"                
                  v-show="form.images.length >= 2"                  
                  :value="bilderActiveIndex + 1"
                  @input="bilderActiveIndex = $event - 1"
                  :length="form.images.length"
                ></v-pagination>
              </div>
            </v-card-text>
            <v-list dense two-line>
              <draggable v-model="bilderModel" handle=".drag-handle">
                <v-list-tile v-for="(item, index) in bilderModel" :key="item.filenameTimestamped"
                  @click.stop="bilderActiveIndex = index"
                  :value="index === bilderActiveIndex"
                  avatar>
                  <v-list-tile-action class="drag-handle" @click.stop>
                    <v-menu offset-y>
                      <v-icon slot="activator" color="grey darken-3">menu</v-icon>
                      <v-list dense>
                        <v-list-tile @click.prevent="form.images.splice(index, 1)">
                          <v-list-tile-title>Löschen</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-list-tile-action>                  
                  <v-list-tile-avatar>
                    <img :src="item.urls.thumb">
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.filename }} | {{ new Date(item.lastModified).toLocaleDateString('de-DE') }}</v-list-tile-title>
                    <v-list-tile-sub-title v-if="item.caption">{{ item.caption }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </draggable>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon>add</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-form ref="formImage" @submit.prevent>
                    <input id="formImageFileInput" type="file" accept="image/*" :disabled="!!loadingCroppie"
                      @change="formSelectImage($event.target.files)">
                  </v-form>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-tab-item>          
          <v-tab-item @transitionend="zutatenTabInit = true">
            <v-card-text>
              <div class="subheading font-weight-bold">Zutaten und Etikett</div>
              <v-textarea v-model.trim="form.labelFreitext"
                rows="1" auto-grow 
                label="Etikett-Freitext"
                hint="Erscheint unterhalb der Zutatenliste" persistent-hint></v-textarea>
              <v-text-field v-model.trim="form.hergestelltAus"
                label="Hergestellt aus"></v-text-field>                
            </v-card-text>
            <v-card-text class="py-0"><v-divider></v-divider></v-card-text>            
            <v-list dense two-line>
              <v-layout>
                <v-subheader>Zutaten</v-subheader>
                <v-spacer></v-spacer>
                <v-btn flat small @click.stop="dialogZutatShow">Anlegen</v-btn>              
              </v-layout>
              <v-card-text class="py-0">
                <v-autocomplete
                  v-model="zutatenModel"
                  :items="zutatenList"
                  :search-input.sync="zutatenSearch"
                  :loading="zutatenLoading"            
                  item-text="title"
                  item-value="_id"
                  label="Zutat hinzufügen"
                  cache-items
                  return-object                  
                  multiple
                  hide-no-data
                  chips
                  deletable-chips
                  single-line
                  box
                  dense
                  menu-props="offsetY, allowOverflow"></v-autocomplete>
              </v-card-text>     
              <draggable v-model="form.zutaten" handle=".drag-handle" group="zutaten">
                <v-list-tile v-for="(item, index) in form.zutaten" :key="item.zutat._id">
                  <v-list-tile-action class="drag-handle" @click.stop>
                    <v-menu offset-y>
                      <v-icon slot="activator">menu</v-icon>
                      <v-list dense>
                        <v-list-tile @click.prevent="form.zutaten.splice(index, 1)">
                          <v-list-tile-title>Löschen</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ `${item.zutat.title} ${item.zutat.details ? `(${item.zutat.details})` : ''}`.trim() }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <vue-slider
                        v-model="item.anteil"
                        :show="zutatenTabInit"
                        tooltip="none"
                        class="mx-1"
                        :dotSize="20"
                        :processStyle="{ backgroundColor: $vuetify.theme.primary }"></vue-slider>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-edit-dialog lazy>
                      <v-list-tile-action-text v-if="item.anteil" class="subheading">{{ item.anteil }} %</v-list-tile-action-text>
                      <v-text-field
                        slot="input"
                        v-model.number="item.anteil"
                        label="Anteil"
                        type="number"
                        autofocus
                        single-line>
                      </v-text-field>
                    </v-edit-dialog>                                    
                  </v-list-tile-action>
                </v-list-tile>
              </draggable>
              <v-card-text class="py-0"><v-divider></v-divider></v-card-text>
              <v-list-tile>
                <v-list-tile-content>
                  <v-container class="pa-0" fill-height><v-layout align-center>
                    <v-label>Fruchtanteil</v-label>
                    <v-flex>
                      <vue-slider 
                        v-model="form.fruchtanteil"
                        :show="zutatenTabInit"
                        tooltip="none"
                        class="mx-1"
                        :dotSize="20"
                        :processStyle="{ backgroundColor: $vuetify.theme.primary }"></vue-slider>
                    </v-flex>
                  </v-layout></v-container>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-edit-dialog lazy>
                    <v-list-tile-action-text v-if="form.fruchtanteil" class="subheading">{{ form.fruchtanteil }} %</v-list-tile-action-text>
                    <v-text-field
                      slot="input"
                      v-model.number="form.fruchtanteil"
                      label="Fruchtanteil"
                      type="number"
                      autofocus
                      single-line>
                    </v-text-field>
                  </v-edit-dialog>                  
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <v-card-text class="pt-0">
              <v-textarea v-model.trim="form.inhaltHtml"
                rows="1" auto-grow
                label="Inhalt-HTML"></v-textarea>                
            </v-card-text>            
          </v-tab-item>
          <v-tab-item>
            <v-card-text class="pb-0">
              <div class="subheading font-weight-bold">Struktur</div>
              <v-select
                v-model="form.produkttyp"
                :items="produkttypen"
                label="Produkttyp"
                dense
                menu-props="offsetY, allowOverflow"
              ></v-select>              
              <v-text-field v-model.trim="form.titleAlt" label="Alternativer Name"></v-text-field>
              <v-checkbox v-model="form.titleMarkt" label="Zeige alt. Name" class="mt-0"></v-checkbox>
              <v-select
                v-model="rezepturModel"
                :items="rezepturenListModel"
                item-value="_id"
                item-text="title"
                :loading="loadingRezepturCreate"
                clearable
                label="Rezeptur"
                :hint="rezepturModel ? `ID: ${rezepturModel}`: ''"
                :persistent-hint="!!rezepturModel"
                dense
                menu-props="offsetY, allowOverflow, closeOnContentClick">
                <template v-slot:append-outer>
                  <v-icon :disabled="!rezepturModel" @click="gotoRezeptur">launch</v-icon>
                </template>
                <template v-slot:append-item>
                  <v-divider></v-divider>
                  <v-list-tile @click="formRezepturCreate"
                    ripple>
                    <v-list-tile-content class="font-weight-bold">
                      <v-list-tile-title>Neue Rezeptur</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>                
              </v-select>
            </v-card-text>
            <v-list dense>
              <v-subheader>Kategorien</v-subheader>
              <v-card-text class="py-0">                
                <v-select
                  v-model="form.categories"
                  :items="categoriesList"
                  item-text="title"
                  item-value="_id"
                  label="Kategorie hinzufügen"
                  return-object
                  multiple
                  chips
                  deletable-chips
                  single-line
                  box
                  dense
                  menu-props="offsetY, allowOverflow">
                  <template slot="item" slot-scope="{ item, tile }">
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                      <v-list-tile-sub-title v-if="item.ancestors.length">
                        {{ item.ancestors.join(', ') }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-card-text>
              <v-list-tile v-for="(category, index) in form.categories" :key="category._id">
                <v-list-tile-action @click.stop>
                  <v-menu offset-y>
                    <v-icon slot="activator">menu</v-icon>
                    <v-list dense>
                      <v-list-tile @click.prevent="form.categories.splice(index, 1)">
                        <v-list-tile-title>Löschen</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ category.title }}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="category.ancestors.length">
                    {{ category.ancestors.join(', ') }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-list dense>
              <v-layout>
                <v-subheader>Merkmale</v-subheader>
                <v-spacer></v-spacer>
                <v-btn flat small @click.stop="dialogMerkmalShow">Anlegen</v-btn>              
              </v-layout>
              <v-card-text class="py-0">
                <v-select
                  v-model="merkmaleModel"
                  :items="merkmaleList"
                  :item-text="merkmal => merkmal.typ ? `${merkmal.typ}: ${merkmal.title}` : merkmal.title"
                  item-value="_id"
                  label="Merkmal hinzufügen"
                  return-object
                  multiple
                  chips
                  deletable-chips
                  single-line
                  box
                  dense
                  menu-props="offsetY, allowOverflow"></v-select>
              </v-card-text>
              <v-list-tile v-for="(item, index) in form.merkmale" :key="item.merkmal._id">
                <v-list-tile-action @click.stop>
                  <v-menu offset-y>
                    <v-icon slot="activator">menu</v-icon>
                    <v-list dense>
                      <v-list-tile @click.prevent="form.merkmale.splice(index, 1)">
                        <v-list-tile-title>Löschen</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.merkmal.typ ? `${item.merkmal.typ}: ${item.merkmal.title}` : item.merkmal.title }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title v-if="item.auspraegung">
                    {{ item.auspraegung}}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action v-if="item.merkmal.auspraegungen.length">
                  <v-menu offset-y>
                    <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                    <v-list dense>
                      <v-list-tile v-for="value in item.merkmal.auspraegungen" :key="value" @click="item.auspraegung = value">
                        <v-list-tile-title>{{ value }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>              
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
        <div style="margin-top: auto">
          <v-divider></v-divider>
          <v-card-text>
            <v-layout>              
              <v-btn 
                v-if="form._id"
                :to="`/katalog/${form.slug}`"
                >Abbrechen</v-btn>            
              <v-spacer></v-spacer>
              <v-btn 
                v-if="!form._id"
                @click.native="formProduktCreateSubmit"
                :disabled="loading"
                color="primary" dark>Hinzufügen</v-btn>
              <v-btn 
                v-else
                @click.native="formProduktUpdateSubmit"
                :disabled="loading"
                color="primary" dark>Speichern</v-btn>
            </v-layout>
          </v-card-text>
        </div>
      </v-layout>
    </v-card>
      
    <!-- DIALOG -->
    <v-dialog v-model="dialogZutat" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Neue Zutat</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formZutat" @submit.prevent>
            <v-text-field v-model.trim="formZutat.title" label="Titel" required :disabled="loadingZutat"></v-text-field>
            <v-textarea rows="1" auto-grow v-model.trim="formZutat.details" label="Details" :disabled="loadingZutat"></v-textarea>
            <v-switch v-model="formZutat.frucht" label="Frucht" :disabled="loadingZutat"></v-switch>
            <v-switch v-model="formZutat.alkoholisch" label="Alkoholisch" :disabled="loadingZutat"></v-switch>
            <v-switch v-model="formZutat.allergen" label="Allergen" :disabled="loadingZutat"></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogZutat = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formZutatSubmit" :disabled="loadingZutat">Hinzufügen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMerkmal" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Neues Merkmal</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formMerkmal" @submit.prevent>
            <v-combobox
              v-model.trim="formMerkmal.typ" 
              :items="Array.from(merkmaleList.map(merkmal => merkmal.typ))"
              label="Typ" 
              required 
              :disabled="loadingMerkmal"
              dense></v-combobox>
            <v-text-field v-model.trim="formMerkmal.title" label="Name" required :disabled="loadingMerkmal"></v-text-field>
            <v-combobox 
              v-model="formMerkmal.auspraegungen"
              label="Ausprägungen"
              multiple 
              chips small-chips deletable-chips
              dense></v-combobox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogMerkmal = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formMerkmalSubmit" :disabled="loadingMerkmal">Hinzufügen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreis" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Neuer Preis</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formPreis" @submit.prevent>              
            <v-text-field v-model.trim="formPreis.title" label="Name" required :disabled="loadingPreis"></v-text-field>
            <v-select
              v-model="formPreis.produkttyp"
              :items="produkttypen"
              label="Produkttyp"
              dense
            ></v-select>
            <v-layout>
              <v-flex xs6>
                <v-text-field v-model.number="formPreis.fuellMenge" label="Füllmenge" required :disabled="loadingPreis"
                :suffix="formPreis.fuellMengeUnit">
                  <template slot="append-outer">
                    <v-menu offset-y>                        
                      <v-icon slot="activator" size="22">fa-balance-scale</v-icon>
                      <v-list dense>
                        <v-list-tile v-for="value in fuellMengeUnits" :key="value" @click="formPreis.fuellMengeUnit = value">
                          <v-list-tile-title>{{ value }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>              
                  </template>
                </v-text-field>
              </v-flex>
              <v-flex xs5 offset-xs1>
                <v-combobox v-model="formPreis.fuellMengeContainer" :items="fuellMengeContainers" label="Behälter" dense
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-text-field v-model.number="formPreis.preis" label="Preis" suffix="€-Cents" required :disabled="loadingPreis"></v-text-field>
            <v-text-field v-model="formPreis.fuellMengeVariant" label="Variant" required :disabled="loadingPreis"></v-text-field>
            <v-layout>
              <v-flex xs6>
                <v-switch v-model="formPreis.fuellMengeSet" label="Set" :disabled="loadingPreis"></v-switch>
              </v-flex>
              <v-flex xs5 offset-xs1>
                <v-text-field v-model.number="formPreis.freightUnits" label="Freight units" required :disabled="loadingPreis"></v-text-field>
              </v-flex>
            </v-layout>            
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogPreis = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formPreisSubmit" :disabled="loadingPreis">Hinzufügen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSKU" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Neues SKU-Partial</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formSKU" @submit.prevent>              
            <v-text-field v-model.trim="formSKU.title" label="Name" required :disabled="loadingPreis"></v-text-field>
            <v-select
              v-model="formSKU.kind"
              :items="['Variant', 'Slot', 'Option']"
              label="Typ"
              dense
            ></v-select>
            <template v-if="formSKU.kind === 'Variant'">
              <v-text-field v-model.trim="formSKU.variantName" label="Variant-Name" required :disabled="loadingPreis"></v-text-field>
              <v-text-field v-model.trim="formSKU.variantValue" label="Variant-Value" required :disabled="loadingPreis"></v-text-field>
            </template>
            <template v-else-if="formSKU.kind === 'Slot'">
              <v-text-field v-model.trim="formSKU.slotName" label="Slot-Name" required :disabled="loadingPreis"></v-text-field>            
              <v-select
                v-model="formSKU.slotMerkmale"
                :items="merkmaleList"
                :item-text="merkmal => merkmal.typ ? `${merkmal.typ}: ${merkmal.title}` : merkmal.title"
                item-value="_id"
                label="Merkmale"
                return-object
                multiple
                chips
                deletable-chips
                single-line
                box
                dense
                menu-props="offsetY"></v-select>
              <v-select
                v-model="formSKU.slotProdukttypen"
                :items="produkttypen"
                label="Produkttypen"
                multiple              
                chips
                deletable-chips
                single-line
                box
                dense
                menu-props="offsetY"></v-select>
              <v-select
                v-model="formSKU.slotDefault"
                :items="produkteKatalog"
                :item-text="produkt => produkt.title"
                item-value="_id"
                label="Vorauswahl"
                return-object
                single-line
                box
                dense
                menu-props="offsetY"></v-select>
            </template>
            <template v-else-if="formSKU.kind === 'Option'">
              <v-text-field v-model.trim="formSKU.optionName" label="Option-Name" required :disabled="loadingPreis"></v-text-field>            
              <v-combobox
                v-model="formSKU.optionValues"
                label="Items"
                multiple
                chips
                deletable-chips
                single-line
                box
                dense
                menu-props="offsetY"></v-combobox>
              <v-select
                v-model="formSKU.optionDefault"
                :items="formSKU.optionValues"
                label="Vorauswahl"
                single-line
                box
                dense
                menu-props="offsetY"></v-select>                
            </template>
            <template v-if="['Slot', 'Option'].includes(formSKU.kind)">
              <v-layout>
                <v-flex xs6>
                  <v-text-field v-model.number="formSKU.fuellMenge" label="Füllmenge" required :disabled="loadingPreis"
                  :suffix="formSKU.fuellMengeUnit">
                    <template slot="append-outer">
                      <v-menu offset-y>                        
                        <v-icon slot="activator" size="22">fa-balance-scale</v-icon>
                        <v-list dense>
                          <v-list-tile v-for="value in fuellMengeUnits" :key="value" @click="formSKU.fuellMengeUnit = value">
                            <v-list-tile-title>{{ value }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>              
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs5 offset-xs1>
                  <v-combobox v-model="formSKU.fuellMengeContainer" :items="fuellMengeContainers" label="Behälter" dense
                  ></v-combobox>
                </v-flex>
              </v-layout>              
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogSKU = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formSKUSubmit" :disabled="loadingPreis">Hinzufügen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogCroppie" v-model="dialogCroppie" :transition="false" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Neues Bild</h3>
        </v-card-title>
        <v-card-text class="py-0">
          <!-- CROPPIE -->
          <vue-croppie
            ref=croppieRef
            :enableExif="true"                
            :enableOrientation="true"
            :enableResize="false"
            :mouseWheelZoom="false"
            :viewport="croppieViewport"
            :boundary="croppieBoundary"
          ></vue-croppie>
          <v-btn small flat @click="$refs.croppieRef.rotate(90)">Rotate</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogCroppie = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat 
            :loading="loadingCroppie === 'upload'" :disabled="!!loadingCroppie || !croppieFile"
            @click.native="formUploadImage">Hochladen</v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProduktDelete" max-width="500">
      <v-card>
        <v-card-text class="subheading">
          <strong>{{ form.title }}</strong> wirklick löschen?
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialogProduktDelete = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="formDeleteProdukt" :disabled="loading">Löschen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-flex>
  </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import draggable from 'vuedraggable'
  import VueSlider from 'vue-slider-component'
  import { VueCroppieComponent as VueCroppie } from 'vue-croppie'  

  import 'vue-slider-component/theme/default.css'
  import 'croppie/croppie.css' // import the croppie css manually

  import store from '@/store'
  import produktHelperMixin from '@/components/mixins/produkt-helper'

  window.EXIF = require('exif-js')

  const emptyProdukt = {
    title: '',
    titleAlt: '',
    titleMarkt: false,
    beschreibung: '',
    produkttyp: 'konfituere',
    // vue-slider-component requires Number
    fruchtanteil: 0,
    zutaten: [],
    inhaltHtml: '',
    merkmale: [],
    categories: [],
    skus: [],
    images: [],
    labelFreitext: '',
    empfehlung: false,
    empfehlungText: '',
    rezeptur: null,
    hergestelltAus: ''
  }

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      activeTab: null,
      zutatenTabInit: false,
      loading: false,
      form: JSON.parse(JSON.stringify(emptyProdukt)),
      originalName: null,
      dialogProduktDelete: false,
      // Zutaten
      dialogZutat: false,
      loadingZutat: false,
      formZutat: {
        title: '',
        details: null,
        frucht: false,
        alkoholisch: false,
        allergen: false
      },
      zutatenSearch: null,
      zutatenList: [],
      zutatenLoading: false,
      loadingRezepturCreate: false,
      rezepturenList: [],
      // Merkmale
      dialogMerkmal: false,
      loadingMerkmal: false,
      formMerkmal: {
        title: '',
        typ: null,
        auspraegungen: []
      },
      // Preise
      dialogPreis: false,
      dialogSKU: false,
      loadingPreis: false,
      formPreis: {
        title: '',
        produkttyp: null,
        fuellMenge: null,
        fuellMengeUnit: 'g',
        fuellMengeContainer: null,
        fuellMengeSet: false,
        fuellMengeVariant: null,
        preis: null,
        freightUnits: 1,
      },
      formSKU: {
        kind: 'Variant',
        title: '',
        variantName: '',
        variantValue: '',
        optionName: '',
        optionValues: [],
        optionDefault: '',
        slotName: '',
        slotMerkmale: [],
        slotProdukttypen: [],
        slotDefault: null,
        fuellMenge: null,
        fuellMengeUnit: 'g',
        fuellMengeContainer: null        
      },      
      // Bilder, Croppie
      bilderSelectedIndex: null,
      dialogCroppie: false,
      loadingCroppie: false,
      croppieFile: null,
      preisSelection: null,
      skuPartialsSelection: []
    }),
    metaInfo () {
      return this.form._id
        ? { title: `${this.originalName} bearbeiten | Admin` }
        : { title: 'Produkt anlegen | Admin' }
    },
    computed: {
      ...mapGetters('admin/categories', ['categoriesList']),
      ...mapGetters('admin/products', [
        'merkmaleList', 'preiseList', 'skuPartialsList',
        'produkttypen', 'fuellMengeUnits', 'fuellMengeContainers'
      ]),
      ...mapGetters('products', ['produkteKatalog']),
      zutatenModel: {
        get () {
          return this.form.zutaten.map(item => item.zutat)
        },
        set (zutaten) {
          this.form.zutaten = zutaten.map(zutat => ({
            zutat,
            anteil: (() => {
              const item = this.form.zutaten.find(item => item.zutat._id === zutat._id)
              return item ? item.anteil : 0
            })()
          }))
        }
      },
      rezepturenListModel () {
        if (this.form.rezeptur && 
          !this.rezepturenList.find(rezeptur => rezeptur._id === this.form.rezeptur)) {
            // Make sure Rezeptur shows up in the select
            return [
              ...this.rezepturenList,
              { _id: this.form.rezeptur, title: this.form.rezeptur }
            ]
        }
        return this.rezepturenList
      },
      rezepturModel: {
        get () {
          return this.form.rezeptur
        },
        set (rezeptur) {
          // Set null (not undefined) if select is cleared
          // undefined will not delete the rezeptur server-side
          this.form.rezeptur = rezeptur ? rezeptur : null
        }
      },
      merkmaleModel: {
        get () {
          return this.form.merkmale.map(item => item.merkmal)
        },
        set (merkmale) {
          this.form.merkmale = merkmale.map(merkmal => ({
            merkmal,
            auspraegung: (() => {
              const item = this.form.merkmale.find(item => item.merkmal._id === merkmal._id)
              return item ? item.auspraegung : null
            })()
          }))
        }
      },
      preiseModel: {
        get () {
          return this.form.skus.map(item => item.preis)
        },
        set (preise) {
          // TODO Mapping preise and skus
          this.form.skus = preise.map(preis => {
            return Object.assign({ inventory: 1 }, this.form.skus.find(item =>
              item.preis._id === preis._id
            ) || {}, { preis })
          })
        }
      },
      bilderActiveIndex: {
        get () {
          return this.form.images[this.bilderSelectedIndex]
            ? this.bilderSelectedIndex
            : (this.form.images.length
              ? (this.bilderSelectedIndex >= this.form.images.length
                ? this.form.images.length - 1
                : 0)
              : null)
        },
        set (index) {
          this.bilderSelectedIndex = index
        }
      },
      bilderModel: {
        get () {
          return this.form.images
        },
        // Retain active index on drag drop
        set (images) {
          this.bilderSelectedIndex = images.findIndex(item =>
            item.filenameTimestamped === this.form.images[this.bilderActiveIndex].filenameTimestamped
          )
          this.form.images = images
        }
      },
      croppieBoundary () {
        // xs12 sm8 md6
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': {
            // < 600px
            var width = Math.round(this.$vuetify.breakpoint.width * 0.75)
            width -= width % 4
            return { width, height: width }
          }
          case 'sm': {
            return { width: 300, height: 300 }
          }
          default: {
            return { width: 360, height: 360 }
          }
        }
      },
      croppieViewport () {
        return { ...this.croppieBoundary, width: this.croppieBoundary.width * (3 / 4) }
      }
    },
    watch: {
      zutatenSearch (val) {
        if (!val) return
        this.zutatenLoading = true
        this.searchZutaten(val).then(zutaten => {
          this.zutatenList = zutaten
        }).finally(() => {
          this.zutatenLoading = false
        })
      },
      activeTab (val) {
        // Replaced by lazy v-tab-item
        this.$nextTick(this.$refs.bilderPaginationRef.onResize)
      }
    },
    created () {
      this.loadCategories()
      this.loadMerkmale()
      this.loadPreise()
      this.getRezepturen().then(rezepturen => this.rezepturenList = rezepturen)
      // Zutaten ephemeral
      this.loadSKUPartials()
      this.loadProdukte()
    },
    // Fires when navigating from /katalog/:slug/bearbeiten to /katalog/anlegen and vice versa
    // Retains state if mounted - similar to beforeRouteUpdate, but provides no reference to this
    async beforeRouteEnter ({ params: { slug }, query: { vorlage } }, from, next) {
      // console.log('beforeRouteEnter')
      try {
        var produkt = JSON.parse(JSON.stringify(emptyProdukt))
        
        if (!slug ^ !vorlage) { // XOR
          produkt = await store.dispatch('products/findProdukt', {
            _id: vorlage, slug, forceReload: true
          })
          produkt = JSON.parse(JSON.stringify(produkt))
          if (vorlage) {
            var props = ['_id', 'slug', 'lfdNr', 'faid']
            props.forEach(prop => { delete produkt[prop] })
            produkt.title += ' (Kopie)'
          }
        }
        // beforeRouteEnter not calling next callback and beforeRouteUpdate not called
        // when using the same component on two routes #2255
        // https://github.com/vuejs/vue-router/issues/2255
        next(vm => {
          // console.log('produkt: ' + JSON.stringify(produkt))
          vm.setData(produkt)
        })
      } catch (err) {
        next('/404')
      }
    },
    async beforeRouteUpdate ({ params: { slug }, query: { vorlage } }, from, next) {
      // console.log('beforeRouteUpdate')
      try {
        var produkt = JSON.parse(JSON.stringify(emptyProdukt))
        if (!slug ^ !vorlage) { // XOR
          produkt = await store.dispatch('products/findProdukt', {
            _id: vorlage, slug, forceReload: true
          })
          produkt = JSON.parse(JSON.stringify(produkt))
          if (vorlage) {
            var props = ['_id', 'slug', 'lfdNr', 'faid']
            props.forEach(prop => { delete produkt[prop] })
            produkt.title += ' (Kopie)'
          }
        }
        // console.log('produkt: ' + JSON.stringify(produkt))
        this.setData(produkt)
        next()
      } catch (err) {
        next('/404')
      }
    },
    methods: {
      setData (produkt) {
        // Reset component state
        this.activeTab = 0
        this.zutatenTabInit = false
        // Update or reset product form
        this.form = produkt
        this.originalName = produkt.title
        this.zutatenList = produkt.zutaten.map(zutatWrapper => zutatWrapper.zutat)
      },
      addSku() {
          this.form.skus.push({preis: this.preisSelection, partials: this.skuPartialsSelection, inventory: 1 })
      },
      swapSku(i, j) {
        const iSku = this.form.skus[i];
        this.$set(this.form.skus, i, this.form.skus[j]);
        this.$set(this.form.skus, j, iSku);
      },
      // Here is the action
      formProduktCreateSubmit () {
        // TODO: Validate form
        if (!this.loading) {
          this.loading = true
          this.createProdukt(this.form).then(produkt => {
            this.$router.push({ path: `/katalog/${produkt.slug}`, query: { forceReload: true } })
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formProduktUpdateSubmit () {
        // TODO: Validate form
        if (!this.loading) {
          this.loading = true
          this.updateProdukt(this.form).then(produkt => {
            this.$router.push({ path: `/katalog/${produkt.slug}`, query: { forceReload: true } })
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formDeleteProdukt () {
        this.loading = true
        this.deleteProdukt(this.form._id).then(() => {
          this.dialogProduktDelete = false
          this.$router.push('/katalog/anlegen')
        }).finally(() => {
          this.loading = false
        })
      },
      formSelectImage (fileList) {
        if (!this.loadingCroppie) {
          let file = null

          for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].type.match(/^image\//)) {
              file = fileList[i]
              break
            }
          }

          if (file !== null) {
            this.loadingCroppie = 'bind'
            // Croppie is dependent on it's container being visible
            // when the bind method is called.
            this.dialogCroppie = true
            this.$nextTick().then(() => {
              return new Promise((resolve, reject) => {
                // Delay some further (transition)
                setTimeout(() => {
                  this.$refs.croppieRef.bind({
                    url: URL.createObjectURL(file),
                    zoom: 0
                  }).then(resolve).catch(reject)
                }, 100)
              })
            }).then(() => {
              this.croppieFile = file
            }).catch(() => {
              this.dialogCroppie = false
            }).finally(() => {
              this.loadingCroppie = false
            })
          }
        }
      },
      formUploadImage () {
        if (!this.loadingCroppie && this.croppieFile) {
          this.loadingCroppie = 'upload'
          const filenameOriginal = this.croppieFile.name
          const lastModifiedMillis = this.croppieFile.lastModified

          this.$refs.croppieRef.result({
            type: 'blob',
            size: 'original',
            format: 'jpeg'
          }).then(blob => {
            return this.uploadImage({
              filename: filenameOriginal,
              blob
            })
          }).then(({ urls, filenameTimestamped }) => {
            this.form.images.push({
              filename: filenameOriginal,
              filenameTimestamped,
              lastModified: new Date(lastModifiedMillis),
              mode: 'portrait',
              caption: '',
              urls
            })
            // Reset File button
            this.$refs.formImage.reset()
            document.getElementById('formImageFileInput').value = ''
            this.$refs.croppieRef.refresh()
            this.croppieFile = null
            this.dialogCroppie = false
            this.bilderActiveIndex = this.form.images.length - 1
          }).catch(err => {
            console.log(err)
          }).finally(() => {
            this.loadingCroppie = false
          })
        }
      },
      dialogZutatShow () {
        this.formZutat.title = ''
        this.formZutat.details = null
        this.formZutat.frucht = false
        this.formZutat.alkoholisch = false
        this.formZutat.allergen = false

        this.dialogZutat = true
      },
      formZutatSubmit () {
        if (!this.loadingZutat) {
          this.loadingZutat = true
          // FIXME: Uncaught promises
          this.createZutat(this.formZutat).then(zutat => {
            // Automatically updated in Store
            this.dialogZutat = false
            // Push not working on computed properties
            this.zutatenModel = [...this.zutatenModel, zutat]
          }).finally(() => {
            this.loadingZutat = false
          })
        }
      },
      // Inactive
      formFilterZutaten (item, queryText, itemText) {
        console.log(item, queryText, itemText)
        return !!queryText
      },
      dialogMerkmalShow () {
        this.formMerkmal.title = ''
        this.formMerkmal.typ = null
        this.formMerkmal.auspraegungen = []

        this.dialogMerkmal = true
      },
      formMerkmalSubmit () {
        if (!this.loadingMerkmal) {
          this.loadingMerkmal = true
          this.createMerkmal(this.formMerkmal).then(merkmal => {
            // Automatically updated in Store
            this.dialogMerkmal = false
            // Push not working on computed properties
            this.merkmaleModel = [...this.merkmaleModel, merkmal]
          }).finally(() => {
            this.loadingMerkmal = false
          })
        }
      },
      dialogPreisShow () {
        this.formPreis.title = ''
        this.formPreis.produkttyp = null
        this.formPreis.fuellMenge = null
        this.formPreis.fuellMengeUnit = 'g'
        this.formPreis.fuellMengeContainer = null
        this.formPreis.fuellMengeSet = false
        this.formPreis.fuellMengeVariant = null
        this.formPreis.preis = null
        this.formPreis.freightUnits = 1

        this.dialogPreis = true
      },
      formPreisSubmit () {
        if (!this.loadingPreis) {
          this.loadingPreis = true
          this.createPreis(this.formPreis).then(preis => {
            // Automatically updated in Store
            this.dialogPreis = false
            // Push not working on computed properties
            this.preiseModel = [...this.preiseModel, preis]
          }).finally(() => {
            this.loadingPreis = false
          })
        }
      },
      dialogSKUShow () {
        this.formSKU.title = '';
        this.formSKU.variantName = '';
        this.formSKU.variantValue = '';
        this.formSKU.optionName = '';
        this.formSKU.optionValues = [];
        this.formSKU.optionDefault = '';
        this.formSKU.slotName = '';
        this.formSKU.slotMerkmale = [];
        this.formSKU.slotProdukttypen = [];
        this.formSKU.slotDefault = null;
        this.formSKU.fuellMenge = null;
        this.formSKU.fuellMengeUnit = 'g';
        this.formSKU.fuellMengeContainer = null;

        this.dialogSKU = true;
      },
      formSKUSubmit () {
        if (!this.loadingPreis) {
          this.loadingPreis = true
          this.createSKUPartial(this.formSKU).then(skuPartial => {
            // Automatically updated in Store
            this.dialogSKU = false
            // Push not working on computed properties
            this.skuPartialsSelection = [...this.skuPartialsSelection, skuPartial]
          }).finally(() => {
            this.loadingPreis = false
          })
        }
      },      
      // Inactive
      calcZutatenFruchtanteil () {
        this.form.fruchtanteil = this.form.zutaten.reduce((accumulator, item) => {
          return item.zutat.frucht ? accumulator + item.anteil : accumulator
        }, null)
      },
      formRezepturCreate () {
        if (!this.loadingRezepturCreate) {
          this.loadingRezepturCreate = true
          this.createRezeptur({ title: this.form.title }).then(rezeptur => {
            this.rezepturenList.push(rezeptur)
            // New and potentially buggy
            this.$nextTick(() => {
              this.form.rezeptur = rezeptur._id
            })
          }).finally(() => {
            this.loadingRezepturCreate = false
          })
        }
      },
      gotoRezeptur () {
        if (this.rezepturModel) {
          this.$router.push(`/naehrwerte/rezeptur/${this.rezepturModel}`)
        }
      },
      ...mapActions('products', [
        'loadProdukte'
      ]),
      ...mapActions('admin/categories', ['loadCategories']),
      ...mapActions('admin/products', [
        'createProdukt', 'updateProdukt', 'deleteProdukt',
        'createZutat', 'searchZutaten',
        'loadMerkmale', 'createMerkmal',
        'loadPreise', 'createPreis',
        'loadSKUPartials', 'createSKUPartial',
        'uploadImage',
      ]),
      ...mapActions('admin/rezeptur', ['getRezepturen', 'createRezeptur'])
    },
    components: {
      draggable,
      VueSlider,
      VueCroppie
    }
  }
</script>