<template>
  <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-form ref="formLebensmittelRef" @submit.prevent>
    <v-card-text class="pb-0">
      <v-text-field
        v-model.trim="lebensmittel.title"
        required
        :hint="lebensmittel._id ? `ID: ${lebensmittel._id}`: ''"
        :persistent-hint="!!lebensmittel._id"
        label="Name"></v-text-field>
      <v-layout>
        <v-flex xs8 class="pr-1">
          <v-text-field
            v-model.number="lebensmittel.referenzWert"
            required
            type="number"
            label="Referenzgröße"></v-text-field>
        </v-flex>
        <v-flex xs4 class="pl-1">
          <v-select
            v-model="lebensmittel.referenzEinheit"
            required
            :items="['g', 'ml']"
            label="Einheit"
            dense
            menu-props="offsetY, allowOverflow"></v-select>
        </v-flex>
      </v-layout>
      <v-text-field
        v-if="lebensmittel.referenzEinheit === 'ml'"
        v-model.number="lebensmittel.volumeToWeightFactor"
        required
        type="number"
        suffix="g/ml"
        :hint="`z.B. gewogen 105 g ⇒ Faktor: ${105 / lebensmittel.referenzWert}`"
        label="Volumen-Gewicht-Faktor"></v-text-field>
      <v-text-field
        v-model.trim="lebensmittel.naehrwertQuelle"
        :rows="1" auto-grow
        placeholder="Nährwert-Quelle"
        label="Bemerkung"></v-text-field>
      <v-autocomplete
        v-model="zutatModel"
        :items="zutatenList"
        :search-input.sync="zutatenSearch"
        :loading="zutatenLoading"
        item-text="title"
        item-value="_id"
        return-object
        :hint="lebensmittel.parent ? `ID: ${lebensmittel.parent._id}`: ''"
        :persistent-hint="!!lebensmittel.parent"        
        label="Zutat"
        cache-items
        clearable
        dense
        menu-props="offsetY, allowOverflow"></v-autocomplete>
    </v-card-text>
    <v-subheader>Nährwertangaben</v-subheader>          
    <v-card-text class="py-0">
      <v-layout>
        <v-flex xs8 class="pr-1">
          <v-text-field
            v-model="brennwertKJModel"
            required
            v-show="brennwertEinheit === 'kJ'"
            type="number"
            :hint="`entspricht ${brennwertKcalModel} kcal`"
            label="Energie"></v-text-field>
          <v-text-field
            v-model="brennwertKcalModel"
            v-show="brennwertEinheit === 'kcal'"
            type="number"
            :hint="`entspricht ${brennwertKJModel} kJ`"
            label="Energie"></v-text-field>            
        </v-flex>
        <v-flex xs4 class="pl-1">
          <v-select
            v-model="brennwertEinheit"
            :items="['kJ', 'kcal']"
            label="Einheit"
            dense
            menu-props="offsetY, allowOverflow"></v-select>
        </v-flex>
      </v-layout>
      <v-text-field
        v-model="lebensmittel.fett.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.fett.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.fett.isLess = !lebensmittel.fett.isLess"
        :prefix="lebensmittel.fett.isLess ? '<' : ''"
        label="Fett"></v-text-field>          
      <v-text-field
        v-model="lebensmittel.fettSaturiert.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.fettSaturiert.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.fettSaturiert.isLess = !lebensmittel.fettSaturiert.isLess"
        :prefix="lebensmittel.fettSaturiert.isLess ? '<' : ''"
        label="gesättigte Fettsäuren"></v-text-field>          
      <v-text-field
        v-model="lebensmittel.carbs.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.carbs.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.carbs.isLess = !lebensmittel.carbs.isLess"
        :prefix="lebensmittel.carbs.isLess ? '<' : ''"
        label="Kohlenhydrate"></v-text-field>          
      <v-text-field
        v-model="lebensmittel.carbsZucker.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.carbsZucker.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.carbsZucker.isLess = !lebensmittel.carbsZucker.isLess"
        :prefix="lebensmittel.carbsZucker.isLess ? '<' : ''"
        label="Zucker"></v-text-field>          
      <v-text-field
        v-model="lebensmittel.eiweiss.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.eiweiss.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.eiweiss.isLess = !lebensmittel.eiweiss.isLess"
        :prefix="lebensmittel.eiweiss.isLess ? '<' : ''"
        label="Eiweiß"></v-text-field>
      <v-text-field
        v-model="lebensmittel.salz.value.$numberDecimal"
        required
        type="number"
        suffix="g"
        :append-outer-icon="lebensmittel.salz.isLess ? 'vertical_align_top' : 'vertical_align_center'"
        @click:append-outer="lebensmittel.salz.isLess = !lebensmittel.salz.isLess"
        :prefix="lebensmittel.salz.isLess ? '<' : ''"
        label="Salz"></v-text-field>
    </v-card-text>
    </v-form>
    <v-expansion-panel id="lebensmittel-details-exp-panel" class="elevation-0">
      <v-expansion-panel-content :disabled="rezepturen.length === 0">
        <template v-slot:header>
          <v-subheader>Verwendet in {{rezepturen.length }} Rezepturen</v-subheader>
        </template>
        <v-list dense subheader>
          <v-list-tile v-for="rezeptur in rezepturen" :key="rezeptur._id"
            :to="`/naehrwerte/rezeptur/${rezeptur._id}`">
            <v-list-tile-content>
              <v-list-tile-title>{{ rezeptur.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>    
    <v-divider></v-divider>
    <v-card-text>
      <v-layout justify-space-around>
        <v-btn color="error" @click="formDelete" :loading="loadingDelete"
          :disabled="!lebensmittel._id || !!rezepturen.length ">Löschen</v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              :loading="loadingSave"
              v-on="on">Speichern</v-btn>
          </template>
          <v-list dense>
            <v-list-tile @click="formSubmit(false)">
              <v-list-tile-title>{{ lebensmittel._id ? 'Aktualisieren' : 'Anlegen' }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="formSubmit(true)" :disabled="!lebensmittel._id">
              <v-list-tile-title>Kopie anlegen</v-list-tile-title>
            </v-list-tile>              
          </v-list>
        </v-menu>
      </v-layout>
    </v-card-text>  
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'

  const emptyLebensmittel = {
    title: 'Neues Lebensmittel',
    referenzWert: 100,
    referenzEinheit: 'g',
    volumeToWeightFactor: 1,
    parent: null,
    naehrwertQuelle: null,
    fett: { value: { $numberDecimal: '0' }, isLess: false },
    carbs: { value: { $numberDecimal: '0' }, isLess: false },
    eiweiss: { value: { $numberDecimal: '0' }, isLess: false },
    salz: { value: { $numberDecimal: '0' }, isLess: false },
    fettSaturiert: { value: { $numberDecimal: '0' }, isLess: false },
    carbsZucker: { value: { $numberDecimal: '0' }, isLess: false },
    brennwertKJ: { value: { $numberDecimal: '0' }, isLess: false }
  }

  export default {
    data: () => ({
      lebensmittel: JSON.parse(JSON.stringify(emptyLebensmittel)),
      rezepturen: [],
      zutatenList: [],
      zutatenSearch: null,
      zutatenLoading: false,
      brennwertKcal: null,
      brennwertEinheit: 'kJ',
      loadingSave: false,
      loadingDelete: false
    }),
    metaInfo: {
      title: 'Lebensmittel bearbeiten',
    },
    computed: {
      zutatModel: {
        get () {
          return this.lebensmittel.parent
        },
        set (zutat) {
          // Set null (not undefined) if select is cleared
          // undefined will not delete the rezeptur server-side
          this.lebensmittel.parent = zutat ? zutat : null
        }
      },
      brennwertKJModel: {
        get () {
          return this.lebensmittel.brennwertKJ.value.$numberDecimal
        },
        set (brennwertKJ) {
          this.brennwertKcal = null
          this.lebensmittel.brennwertKJ.value.$numberDecimal = brennwertKJ
        }
      },
      brennwertKcalModel: {
        get () {
          return this.brennwertKcal
            ? this.brennwertKcal
            : `${Math.round(parseFloat(this.brennwertKJModel) / 4.184)}`
        },
        set (brennwertKcal) {
          this.brennwertKcal = brennwertKcal
          const brennwertKJ = `${Math.round(parseFloat(brennwertKcal) * 4.184)}`
          this.lebensmittel.brennwertKJ.value.$numberDecimal = brennwertKJ
        }
      },
      naehrwerteDatabase () {
        return ["fett", "carbs", "eiweiss", "salz", "fettSaturiert", "carbsZucker", "brennwertKJ"]
          .reduce((naehrwerteDatabase, naehrwertType) => {
            naehrwerteDatabase[naehrwertType] = {
              value: this.lebensmittel[naehrwertType].value.$numberDecimal,
              isLess: this.lebensmittel[naehrwertType].isLess,
            }
            return naehrwerteDatabase
          }, {})
      },
      loading () {
        return this.loadingSave || this.loadingDelete
      }
    },
    watch: {
      zutatenSearch (val) {
        if (!val) return
        this.zutatenLoading = true
        this.searchZutaten(val).then(zutaten => {
          this.zutatenList = zutaten
        }).finally(() => {
          this.zutatenLoading = false
        })
      }
    },
    async beforeRouteEnter ({ params: { lebensmittelId }, query: { zutat: zutatId, title } }, from, next) {
      var lebensmittel = JSON.parse(JSON.stringify(emptyLebensmittel))
      var rezepturen = []
      var zutatenList = []
      try {
        if (lebensmittelId) {
          const responseData = await store.dispatch('admin/rezeptur/findLebensmittel', lebensmittelId)
          lebensmittel = responseData.lebensmittel
          rezepturen = responseData.rezepturen
          if (lebensmittel.parent) {
            zutatenList.push(lebensmittel.parent)
          }
        } else {
          // For anlegen route
          if (zutatId) {
            const zutat = await store.dispatch('admin/products/findZutat', zutatId)
            lebensmittel.parent = zutat
            zutatenList.push(zutat)
          }
          if (title) {
            lebensmittel.title = title
          }
        }
      } catch (err) {
        return next('/404')
      }
      next(vm => {
        vm.lebensmittel = lebensmittel
        vm.rezepturen = rezepturen
        vm.zutatenList = zutatenList
      })
    },
    async beforeRouteUpdate ({ params: { lebensmittelId }, query: { zutat: zutatId, title } }, from, next) {
      var lebensmittel = JSON.parse(JSON.stringify(emptyLebensmittel))
      var rezepturen = []
      var zutatenList = []      
      try {
        if (lebensmittelId) {
          const responseData = await store.dispatch('admin/rezeptur/findLebensmittel', lebensmittelId)
          lebensmittel = responseData.lebensmittel
          rezepturen = responseData.rezepturen
          if (lebensmittel.parent) {
            zutatenList.push(lebensmittel.parent)
          }
        } else {
          if (zutatId) {
            const zutat = await store.dispatch('admin/products/findZutat', zutatId)
            lebensmittel.parent = zutat
            zutatenList.push(zutat)
          }
          if (title) {
            lebensmittel.title = title
          }
        }  
      } catch (err) {
        return next('/404')
      }
      this.lebensmittel = lebensmittel
      this.rezepturen = rezepturen
      this.zutatenList = zutatenList
      this.resetComponent()
      next()
    },
    methods: {
      formSubmit (makeCopy = false) {
        if (!this.loading && this.$refs.formLebensmittelRef.validate()) {
          this.loadingSave = true         
          const lebensmittelOverwrite = {}
          var fn = this.updateLebensmittel
          if (!this.lebensmittel._id || makeCopy) {
            fn = this.createLebensmittel
            if (makeCopy) {
              lebensmittelOverwrite._id = undefined
              if (this.lebensmittel.parent && this.lebensmittel.parent.title === this.lebensmittel.title) {
                lebensmittelOverwrite.title = `${this.lebensmittel.title} (neu)`
              }
            }
          }
          fn(Object.assign({}, this.lebensmittel, lebensmittelOverwrite)).then(lebensmittel => {
            if (this.lebensmittel._id && !makeCopy) {
              // updateLebensmittel
              this.lebensmittel = lebensmittel
              this.resetComponent()
            } else {
              // createLebensmittel
              this.$router.push(`/naehrwerte/lebensmittel/${lebensmittel._id}`)
            }
          }).finally(() => {
            this.loadingSave = false
          })
        }
      },
      formDelete () {
        if (!this.loading && !!this.lebensmittel._id) {
          this.loadingDelete = true
          this.deleteLebensmittel(this.lebensmittel._id).then(() => {
            this.$router.push('/naehrwerte/lebensmittel')
          }).finally(() => {
            this.loadingDelete = false
          })
        }
      },
      resetComponent() {        
        this.zutatenSearch = null
        this.brennwertKcal = null
        this.brennwertEinheit = 'kJ'
      },
      ...mapActions('admin/products', ['searchZutaten']),
      ...mapActions('admin/rezeptur', ['createLebensmittel', 'updateLebensmittel', 'deleteLebensmittel']),
    }
  }
</script>

<style>
  #lebensmittel-details-exp-panel .v-expansion-panel__header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
</style>